import _ from 'lodash';
import React, { useEffect } from 'react';
import variables from 'styles/_variables.module.scss';
import NoModules from 'assets/images/NoModules';
import TemplateNoLayouts from 'assets/images/TemplateNoLayouts';
import Button from 'components/Button/Button';
import ModuleBundleDocumentLink from 'components/ModuleBundleDocumentLink';
import NavBar from 'components/NavBar/NavBar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearStorageState } from 'store/persistentState';
import { setMergedSimulationCombinations, getModuleBundleTemplateDataRequest, getTemplateDataRequest, selectModuleBundle, selectSimulationDataLoadingStatus, selectMergedSimulationCombinations, selectTemplate, selectTemplateHasLayouts, removeAllLayouts, areSearchParamsSame, setSearchString, resetSimulationState, selectErrorTemplateData, selectErrorModuleBundleData, selectTemplateReplacedLayouts, } from 'store/simulationSlice';
import { getDocumentFromQueryString, mergeSimulationCombinations, sortScreensByTemplates } from 'utils';
import Disclaimer from './components/Disclaimer';
import FullPageLoading from './components/FullPageLoading';
import SimulationData from './components/SimulationData';
import SimulationErrorPage from './components/SimulationErrorPage';
import styles from './styles.module.scss';
var Simulation = function () {
    var searchString = window.location.search;
    var searchParams = new URLSearchParams(searchString);
    var moduleBundleQuery = getDocumentFromQueryString(searchParams.get('bundle'));
    var templateQuery = getDocumentFromQueryString(searchParams.get('template'));
    var dispatch = useAppDispatch();
    var moduleBundle = useAppSelector(selectModuleBundle);
    var template = useAppSelector(selectTemplate);
    var shouldUpdateData = !useAppSelector(areSearchParamsSame(searchString));
    var errorTemplateData = useAppSelector(selectErrorTemplateData);
    var errorModuleBundleData = useAppSelector(selectErrorModuleBundleData);
    var templateHasLayouts = useAppSelector(selectTemplateHasLayouts);
    var templateReplacedLayouts = useAppSelector(selectTemplateReplacedLayouts);
    var isClearAllButtonDisabled = Object.keys(templateReplacedLayouts).length === 0;
    useEffect(function () {
        if (shouldUpdateData) {
            dispatch(resetSimulationState());
            dispatch(setSearchString(searchString));
        }
        if (shouldUpdateData || errorTemplateData) {
            dispatch(getTemplateDataRequest({
                documentId: Number(templateQuery.documentId),
                majorVersion: Number(templateQuery.majorVersion),
                minorVersion: Number(templateQuery.minorVersion),
            }));
        }
        if (shouldUpdateData || errorModuleBundleData) {
            dispatch(getModuleBundleTemplateDataRequest({
                documentId: Number(moduleBundleQuery.documentId),
                majorVersion: Number(moduleBundleQuery.majorVersion),
                minorVersion: Number(moduleBundleQuery.minorVersion),
            }));
        }
    }, [shouldUpdateData]);
    var moduleBundleLeftPanel = useAppSelector(selectMergedSimulationCombinations);
    var isEmptyModuleBundle = Boolean(errorModuleBundleData) || moduleBundleLeftPanel.length === 0;
    useEffect(function () {
        if (moduleBundleLeftPanel.length === 0) {
            var temlateSections = _(template.screens).flatMap('sections').value();
            var simulationCombinations = mergeSimulationCombinations(moduleBundle, temlateSections);
            var sortedSimulationCombinations = sortScreensByTemplates(simulationCombinations, temlateSections);
            dispatch(setMergedSimulationCombinations(sortedSimulationCombinations));
        }
    }, [moduleBundle, template]);
    var isSimulationDataLoading = useAppSelector(selectSimulationDataLoadingStatus);
    var moduleBundleName = (moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.name)
        ? "".concat(moduleBundle.name, " (v").concat(moduleBundleQuery.majorVersion, ".").concat(moduleBundleQuery.minorVersion, ")")
        : '';
    var handleClearAllButtonClick = function () {
        dispatch(removeAllLayouts());
        clearStorageState();
        dispatch(resetSimulationState());
        dispatch(getModuleBundleTemplateDataRequest({
            documentId: Number(moduleBundleQuery.documentId),
            majorVersion: Number(moduleBundleQuery.majorVersion),
            minorVersion: Number(moduleBundleQuery.minorVersion),
        }));
        dispatch(getTemplateDataRequest({
            documentId: Number(templateQuery.documentId),
            majorVersion: Number(templateQuery.majorVersion),
            minorVersion: Number(templateQuery.minorVersion),
        }));
    };
    var renderSwitch = function (isModuleBundleEmpty, hasLayouts, loading) {
        if (loading) {
            return React.createElement(FullPageLoading, null);
        }
        else if (!hasLayouts) {
            return (React.createElement(SimulationErrorPage, { text: "There are no reusable layouts in the selected channel" },
                React.createElement(TemplateNoLayouts, null)));
        }
        else if (isModuleBundleEmpty) {
            return (React.createElement(SimulationErrorPage, { text: "There are no modules in the module bundle" },
                React.createElement(NoModules, null)));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(Disclaimer, null),
                React.createElement(SimulationData, { template: template, moduleBundleLeftPanel: moduleBundleLeftPanel })));
        }
    };
    return (React.createElement("main", { className: styles.main },
        React.createElement(NavBar, { isModuleCombinationLabelShown: true, discardPositionFixed: true },
            React.createElement("div", { className: 'w-100 d-flex align-items-center justify-content-between' },
                React.createElement(ModuleBundleDocumentLink, { headerClassName: true, url: moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.veevaUrl, linkText: moduleBundleName }),
                templateHasLayouts && !isEmptyModuleBundle && (React.createElement("div", { className: "ps-3" },
                    React.createElement(Button, { type: 'button', isDisabled: isClearAllButtonDisabled, label: 'Clear All', styleOverride: {
                            backgroundColor: isClearAllButtonDisabled
                                ? variables.clearAllButtonDisabled
                                : variables.clearAllButtonPrimary,
                            color: 'black',
                            marginBottom: 0,
                            padding: '5px 15px',
                            fontSize: variables.buttonFontSize,
                            borderRadius: variables.buttonBorderRadius,
                            whiteSpace: 'nowrap',
                        }, onClick: handleClearAllButtonClick }))))),
        renderSwitch(isEmptyModuleBundle, templateHasLayouts, isSimulationDataLoading)));
};
export default Simulation;
