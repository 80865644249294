var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { inflect } from 'inflection';
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import ModulesModal from 'components/ModulesModal';
import NavBar from 'components/NavBar/NavBar';
import RecipeModal from 'pages/Combinations/components/RecipeModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectModulesBundles, selectModulesInfo, selectModulesSections } from 'store/modules/slice';
import { resetRecipe } from 'store/recipeSlice';
import styles from './Combinations.module.scss';
import CombinationPreview from './components/CombinationPreview';
import CombinationsList from './components/CombinationsList';
import CombinationsListToggle from './components/CombinationsListToggle';
import { useCombinations } from './useCombinations';
import { buildRecipeCombinations } from './utils';
var Combinations = function () {
    var dispatchToRedux = useAppDispatch();
    var info = useAppSelector(selectModulesInfo);
    var sections = useAppSelector(selectModulesSections);
    var bundles = useAppSelector(selectModulesBundles);
    var _a = useCombinations(sections, bundles), combinations = _a.combinations, combinationsNumber = _a.combinationsNumber, combinationsPossibleNumber = _a.combinationsPossibleNumber, removeCombinations = _a.removeCombinations;
    var _b = useState(NaN), activeIndex = _b[0], setActiveIndex = _b[1];
    var _c = useState([]), selected2Indexes = _c[0], setSelected2Indexes = _c[1];
    var onSelectToggle = useCallback(function (idx) { return setSelected2Indexes(function (list) {
        if (list.some(function (item) { return item === idx; })) {
            return list.filter(function (item) { return item !== idx; });
        }
        else {
            return __spreadArray(__spreadArray([], list, true), [idx], false).sort(function (a, b) { return a - b; });
        }
    }); }, []);
    var removeCombinationsHandler = function (indexes, showToast) {
        if (showToast === void 0) { showToast = true; }
        removeCombinations(indexes, showToast);
        setSelected2Indexes(function (list) { return list.filter(function (idx) { return !indexes.includes(idx); }); });
    };
    useEffect(function () { return setActiveIndex(function (prev) {
        var next = combinations.findIndex(function (item, idx) { return item && idx >= prev; });
        return next > -1 ? next : combinations.findIndex(function (i) { return i; });
    }); }, [combinations]);
    var _d = useState(false), isModulesModalOpen = _d[0], setIsModulesModalOpen = _d[1];
    var onModulesModalFormSubmit = function () {
        setActiveIndex(0);
        setSelected2Indexes([]);
        setIsModulesModalOpen(false);
    };
    var _e = useState(false), isRecipeModalOpen = _e[0], setIsRecipeModalOpen = _e[1];
    var _f = useState([]), recipeCombinations = _f[0], setRecipeCombinations = _f[1];
    var recipeModalCloseHandler = function () {
        setIsRecipeModalOpen(false);
        dispatchToRedux(resetRecipe());
    };
    useEffect(function () {
        if (isRecipeModalOpen && bundles && sections) {
            var selectedCombinations = selected2Indexes.map(function (idx) { return combinations[idx]; });
            var list = buildRecipeCombinations(selectedCombinations, bundles, sections);
            setRecipeCombinations(list);
        }
    }, [isRecipeModalOpen, selected2Indexes, bundles, sections]);
    if (!info || !sections || !bundles) {
        return React.createElement(Navigate, { to: "/" });
    }
    return (React.createElement(React.Fragment, null,
        isRecipeModalOpen && (React.createElement(RecipeModal, { permutations: recipeCombinations, onModalClose: recipeModalCloseHandler, onRecipeSuccess: function () { return removeCombinationsHandler(selected2Indexes, false); } })),
        isModulesModalOpen && (React.createElement(ModulesModal, { jobZeroId: info.jobZero.id, moduleBundleId: info.moduleBundle.id, onModalClose: function () { return setIsModulesModalOpen(false); }, onFormSubmit: onModulesModalFormSubmit, isNoMoreToAdd: combinationsNumber >= combinationsPossibleNumber })),
        React.createElement(NavBar, { textContent: info.jobZero.name }),
        React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.container__left },
                React.createElement("div", { className: styles.container__left__header },
                    React.createElement("div", null, "".concat(combinationsNumber, " ").concat(inflect('combination', combinationsNumber), " added")),
                    React.createElement("div", null,
                        combinationsPossibleNumber,
                        " possible")),
                React.createElement("div", { className: styles.container__left__actions },
                    React.createElement(Button, { type: "button", intent: "secondary", isDisabled: false, label: "Add More Combinations", onClick: function () { return setIsModulesModalOpen(true); } }),
                    React.createElement(Button, { type: "button", isDisabled: selected2Indexes.length === 0, label: selected2Indexes.length ? "Build Selected (".concat(selected2Indexes.length, ")") : 'Build Selected', onClick: function () { return setIsRecipeModalOpen(true); } })),
                React.createElement("div", { className: styles.container__left__counters },
                    React.createElement(CombinationsListToggle, { combinations: combinations, selectedIndexes: selected2Indexes, onChangeSelection: setSelected2Indexes }),
                    selected2Indexes.length > 0 && (React.createElement("div", { className: styles.container__left__remove_selected, onClick: function () { return removeCombinationsHandler(selected2Indexes); }, onKeyUp: function () { return removeCombinationsHandler(selected2Indexes); } }, "Remove Selected"))),
                React.createElement(CombinationsList, { className: "".concat(styles.container__left__combinations, " flex-grow-1"), combinations: combinations, sections: sections, selectedIndexes: selected2Indexes, activeIndex: activeIndex, onClick: setActiveIndex, onToggle: onSelectToggle })),
            React.createElement(CombinationPreview, { sections: sections, bundles: bundles, combination: combinations[activeIndex], index: activeIndex, onRemove: function () { return removeCombinationsHandler([activeIndex]); } }))));
};
export default Combinations;
