/* eslint-disable max-len */
import React from 'react';
var EmailTemplate = function () {
    return (React.createElement("svg", { width: "80", height: "80", viewBox: "0 0 80 80", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_661_134)" },
            React.createElement("rect", { width: "80", height: "80", fill: "white", fillOpacity: "0.01" }),
            React.createElement("g", { clipPath: "url(#clip1_661_134)" },
                React.createElement("path", { d: "M0.249756 17.7742C0.753847 15.5654 1.99303 13.5933 3.76436 12.1807C5.5357 10.7682 7.73418 9.99905 9.99976 9.99921H69.9998C72.2653 9.99905 74.4638 10.7682 76.2351 12.1807C78.0065 13.5933 79.2457 15.5654 79.7498 17.7742L39.9998 42.0692L0.249756 17.7742ZM-0.000244141 23.4842V59.0042L29.0148 41.2142L-0.000244141 23.4842ZM33.8048 44.1492L0.954756 64.2842C1.76639 65.9956 3.04739 67.4413 4.64866 68.4531C6.24994 69.4649 8.10563 70.001 9.99976 69.9992H69.9998C71.8936 69.9996 73.7486 69.4623 75.3489 68.4497C76.9493 67.437 78.2292 65.9908 79.0398 64.2792L46.1898 44.1442L39.9998 47.9292L33.8048 44.1442V44.1492ZM50.9848 41.2192L79.9998 59.0042V23.4842L50.9848 41.2142V41.2192Z", fill: "url(#paint0_linear_661_134)" }))),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_661_134", x1: "39.9998", y1: "9.99921", x2: "39.9998", y2: "69.9992", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#00857C" }),
                React.createElement("stop", { offset: "1", stopColor: "#29AAA1" })),
            React.createElement("clipPath", { id: "clip0_661_134" },
                React.createElement("rect", { width: "80", height: "80", fill: "white" })),
            React.createElement("clipPath", { id: "clip1_661_134" },
                React.createElement("rect", { width: "80", height: "80", fill: "white" })))));
};
export default EmailTemplate;
