var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import LayoutPreview from 'components/LayoutPreview';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
import ActionPanel from './ActionPanel';
import CancelPanel from './CancelPanel';
import styles from './styles.module.scss';
var Section = function (_a) {
    var _b;
    var layouts = _a.layouts, layoutVariants = _a.layoutVariants, sectionId = _a.id, displayName = _a.displayName, width = _a.width, scrollContainer = _a.scrollContainer, screenId = _a.screenId, matchedScreens = _a.matchedScreens, onCnahgeLayoutVariant = _a.onCnahgeLayoutVariant;
    // accroding to the business logic only one layout should be in the section,
    // but in the theory Veeva may return more than one
    var activeLayout = layouts[0];
    var activeLayoutIdx = layoutVariants.findIndex(function (variant) { return variant.id === activeLayout.id; });
    var _c = useState(false), isSectionHighlited = _c[0], setSectionHighlighted = _c[1];
    var _d = useState(null), correspondingScreen = _d[0], setCorrespondingScreen = _d[1];
    var sectionRef = useRef(null);
    var scrollSectionToCenter = function () {
        setTimeout(function () {
            var _a;
            (_a = sectionRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
        });
    };
    var fixSectionTopPosition = function () {
        var _a, _b, _c, _d, _e, _f;
        var containerTop = (_c = (_b = (_a = scrollContainer === null || scrollContainer === void 0 ? void 0 : scrollContainer.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) === null || _b === void 0 ? void 0 : _b.top) !== null && _c !== void 0 ? _c : 0;
        var sectionTop = (_f = (_e = (_d = sectionRef.current) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect()) === null || _e === void 0 ? void 0 : _e.top) !== null && _f !== void 0 ? _f : 0;
        if (Math.ceil(sectionTop) < Math.ceil(containerTop)) {
            setTimeout(function () {
                var _a;
                (_a = sectionRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
            });
        }
    };
    useEffect(function () {
        var focusSection = function (focusedSectionId) {
            setSectionHighlighted(sectionId === focusedSectionId);
        };
        var scrollSection = function (scrolledSection) {
            if (sectionId === scrolledSection) {
                scrollSectionToCenter();
            }
        };
        eventEmitter.addListener(EVENTS.FOCUS_SECTION_RIGHT, focusSection);
        eventEmitter.addListener(EVENTS.SCROLL_SECTION_RIGHT, scrollSection);
        return function () {
            eventEmitter.removeListener(EVENTS.FOCUS_SECTION_RIGHT, focusSection);
            eventEmitter.removeListener(EVENTS.SCROLL_SECTION_RIGHT, scrollSection);
        };
    }, [sectionId]);
    useEffect(function () {
        var matchedLayout = layoutVariants.find((function (layoutVariant) { return layouts.find(function (layout) { return layout.id === layoutVariant.id; }); }));
        if (matchedLayout) {
            var currentMatchedScreen = matchedScreens.find(function (matchedScreen) {
                return matchedScreen.layouts.find(function (matchedScreenLayout) { return matchedScreenLayout.id === matchedLayout.id; });
            });
            if (currentMatchedScreen) {
                setCorrespondingScreen(currentMatchedScreen);
            }
        }
    }, [layoutVariants, layouts]);
    var isEmptySection = _.isEmpty(layouts);
    if (isEmptySection) {
        return null;
    }
    var onClick = function () {
        var reversedMatchedScreens = __spreadArray([], matchedScreens, true).reverse();
        for (var _i = 0, reversedMatchedScreens_1 = reversedMatchedScreens; _i < reversedMatchedScreens_1.length; _i++) {
            var matchedScreen = reversedMatchedScreens_1[_i];
            eventEmitter.emit(EVENTS.EXPAND_ACCORDION, matchedScreen === null || matchedScreen === void 0 ? void 0 : matchedScreen.id);
        }
        fixSectionTopPosition();
    };
    var onChangeLayout = function (layout) {
        var currentScreen = matchedScreens.find(function (matchedScreen) {
            return matchedScreen.layouts.find(function (matchedScreenLayout) { return matchedScreenLayout.id === layout.id; });
        });
        if (currentScreen) {
            onCnahgeLayoutVariant(layout, currentScreen);
            eventEmitter.emit(EVENTS.EXPAND_ACCORDION, (currentScreen === null || currentScreen === void 0 ? void 0 : currentScreen.id) || screenId, layout.id);
            fixSectionTopPosition();
        }
    };
    var onClearLayout = function () {
        if (correspondingScreen) {
            onCnahgeLayoutVariant(null, correspondingScreen);
            fixSectionTopPosition();
        }
    };
    var classes = classNames(styles.sectionContainer, (_b = {},
        _b[styles.withLayoutVariants] = !!layoutVariants.length,
        _b[styles.highlighted] = isSectionHighlited,
        _b));
    return (React.createElement("div", { onClick: onClick, className: classes, "data-testid": "simulation-section" },
        React.createElement("div", { className: styles.sectionStickyPanel },
            React.createElement("div", null, !!layoutVariants.length && (React.createElement(ActionPanel, { displayName: displayName, layoutVariantIdx: activeLayoutIdx, layoutVariants: layoutVariants, onChangeLayout: onChangeLayout })))),
        React.createElement("div", { ref: sectionRef, title: displayName, className: styles.section, style: { width: width } }, layouts.map(function (_a) {
            var id = _a.id, documentId = _a.documentId;
            return (React.createElement(LayoutPreview, { key: id, documentId: documentId }));
        })),
        React.createElement("div", { className: styles.sectionStickyPanel },
            React.createElement("div", null, activeLayoutIdx >= 0 && (React.createElement(CancelPanel, { className: "shadow", onClick: onClearLayout }))))));
};
export default Section;
