import classNames from 'classnames';
import React from 'react';
import LinkIcon from 'assets/images/LinkIcon';
import styles from './styles.module.scss';
var ModuleBundleDocumentLink = function (_a) {
    var _b;
    var headerClassName = _a.headerClassName, url = _a.url, linkText = _a.linkText;
    if (!linkText) {
        return null;
    }
    return (React.createElement("div", { className: classNames(styles.linkWrapper, (_b = {},
            _b[styles.moduleBundleHeader] = headerClassName,
            _b)) },
        React.createElement("div", null,
            React.createElement("span", { className: styles.label }, "Module Bundle:"),
            React.createElement("a", { className: styles.link, href: url, target: "_blank", rel: "noreferrer" },
                linkText,
                React.createElement(LinkIcon, null)))));
};
export default ModuleBundleDocumentLink;
