import React from 'react';
import NoResults from 'assets/images/NoResults';
var NoContentFound = function () { return (React.createElement("tr", null,
    React.createElement("td", { className: "border-0", colSpan: 3 },
        React.createElement("div", { className: "d-flex flex-column align-items-center my-5" },
            React.createElement(NoResults, null),
            React.createElement("div", { className: "mt-3 text-center" },
                React.createElement("p", { className: "fw-bold" }, "No content found"),
                React.createElement("p", null,
                    "Your filter set did not match any projects.",
                    React.createElement("br", null),
                    "Please try again.")))))); };
export default NoContentFound;
