import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoModuleBundle from 'assets/images/NoModuleBundle';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import TemplateCard from 'components/TemplateCard';
import { selectModuleBundle, getModuleBundleChannelsDataRequest, selectModuleBundleDataLoadingStatus, } from 'store/channelsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sortModuleBundlesTemplatesByResourceType } from 'utils';
import { makeQuery } from 'utils/selectChannelQuery';
import Header from './components/Header';
import styles from './styles.module.scss';
export var spinnerTestId = 'spinnerTestId';
var Channels = function () {
    var _a, _b, _c, _d;
    var searchParams = new URLSearchParams(window.location.search);
    var documentId = Number((_a = searchParams.get('documentId')) !== null && _a !== void 0 ? _a : '');
    var majorVersion = Number((_b = searchParams.get('MajorVersion')) !== null && _b !== void 0 ? _b : '');
    var minorVersion = Number((_c = searchParams.get('MinorVersion')) !== null && _c !== void 0 ? _c : '');
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var moduleBundle = useAppSelector(selectModuleBundle);
    var isEmptyModuleBundle = !((_d = moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.channels) === null || _d === void 0 ? void 0 : _d.length);
    var isModuleBundleDataLoading = useAppSelector(selectModuleBundleDataLoadingStatus);
    var showChannels = _(moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.channels)
        .uniqBy('resourceType')
        .sortBy(function (_a) {
        var resourceType = _a.resourceType;
        return sortModuleBundlesTemplatesByResourceType(resourceType);
    })
        .value();
    useEffect(function () {
        dispatch(getModuleBundleChannelsDataRequest({ documentId: documentId, majorVersion: majorVersion, minorVersion: minorVersion }));
    }, [documentId, majorVersion, minorVersion]);
    var clickOnChannel = function (template) {
        navigate({
            pathname: 'combinations',
            search: makeQuery({ template: template, bundle: { documentId: documentId, majorVersion: majorVersion, minorVersion: minorVersion } }),
        });
    };
    // DCC-7572 - Select a channel automatically
    if (moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.channels.length) {
        // setTimeout - redirect must be after render (React requirements)
        setTimeout(function () {
            clickOnChannel(moduleBundle.channels[0]);
        });
    }
    var renderModuleBundleTemplates = function () {
        if (isModuleBundleDataLoading) {
            return (React.createElement("div", { "data-testid": spinnerTestId, className: "d-flex h-100 flex-column align-items-center justify-content-center" },
                React.createElement(LoadingSpinner, null)));
        }
        if (isEmptyModuleBundle) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Header, { moduleBundle: moduleBundle, majorVersion: majorVersion, minorVersion: minorVersion }),
                React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center flex-grow-1" },
                    React.createElement("div", { className: "mt-3 text-center" },
                        React.createElement(NoModuleBundle, null),
                        React.createElement("h5", { className: "fw-bold" }, "No Channel"),
                        React.createElement("p", null, "No channel found for this module bundle")))));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, { moduleBundle: moduleBundle, majorVersion: majorVersion, minorVersion: minorVersion }),
            React.createElement("div", { className: styles.TemplateContainer }, showChannels.map(function (template) { return (React.createElement(TemplateCard, { key: template.documentId, template: template, redirectToChannel: clickOnChannel })); }))));
    };
    return (React.createElement("main", { className: "d-flex flex-column align-items-stretch" },
        React.createElement(NavBar, { isModuleCombinationLabelShown: true, discardPositionFixed: true }),
        React.createElement("div", { className: "px-3 px-lg-5 container-fluid d-flex flex-column flex-grow-1" }, renderModuleBundleTemplates())));
};
export default Channels;
