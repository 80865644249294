// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_screen__bpLDb {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  position: relative;
}
.styles_screen__bpLDb:last-child {
  margin-bottom: 0;
}
.styles_screen__bpLDb > * {
  z-index: 2;
}

.styles_backdrop__-FIsr {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  min-width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.styles_backdrop__-FIsr::before, .styles_backdrop__-FIsr::after {
  content: "";
  width: 2rem;
  min-width: 2rem;
}
.styles_backdrop__-FIsr div {
  background: #f5f5f5;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/Screen/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,UAAA;AAAJ;;AAKA;EACE,UAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,8BAAA;AAFF;AAIE;EAEE,WAAA;EACA,WAAA;EACA,eAAA;AAHJ;AAME;EACE,mBAAA;EACA,2CAAA;AAJJ","sourcesContent":[".screen {\n  margin-bottom: 32px;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 2px;\n  position: relative;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  >* {\n    z-index: 2;\n  }\n}\n\n// should mimic styles from section container\n.backdrop {\n  z-index: 1;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  min-width: 100%;\n  flex-direction: row;\n  justify-content: space-between;\n\n  &::before,\n  &::after {\n    content: '';\n    width: 2rem;\n    min-width: 2rem;\n  }\n\n  div {\n    background: #f5f5f5;\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen": `styles_screen__bpLDb`,
	"backdrop": `styles_backdrop__-FIsr`
};
export default ___CSS_LOADER_EXPORT___;
