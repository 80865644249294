import AdTemplate from 'assets/images/AdTemplate';
import AlertTemplate from 'assets/images/AlertTemplate';
import DefaultTemplate from 'assets/images/DefaultTemplate';
import EmailTemplate from 'assets/images/EmailTemplate';
import { ChannelType } from 'const';
export function getSimulationChannelThumbnail(resourceType) {
    switch (resourceType) {
        case ChannelType.EMAIL:
            return EmailTemplate;
        case ChannelType.ALERT:
            return AlertTemplate;
        case ChannelType.AD:
            return AdTemplate;
        default:
            return DefaultTemplate;
    }
}
