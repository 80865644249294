import React, { useMemo } from 'react';
import { combinationCreateKey } from '../utils';
import CombinationsListItem from './CombinationsListItem';
var CombinationsList = function (props) {
    var sections = props.sections, combinations = props.combinations, activeIndex = props.activeIndex, selectedIndexes = props.selectedIndexes, onClick = props.onClick, onToggle = props.onToggle, className = props.className;
    var sectionsToView = useMemo(function () { return sections.map(function (item, idx) { return [item, idx]; }).filter(function (_a) {
        var _b;
        var item = _a[0];
        return item.placeholder && ((_b = item.bundleIndexes) === null || _b === void 0 ? void 0 : _b.length) > 0;
    }); }, [sections]);
    var selectedMap = useMemo(function () { return selectedIndexes.reduce(function (acc, item) {
        acc[item] = true;
        return acc;
    }, new Array(combinations.length)); }, [selectedIndexes]);
    return (React.createElement("div", { className: className }, combinations.map(function (combination, index) { return combination
        ? React.createElement(CombinationsListItem, { key: combinationCreateKey(combination), sectionsToView: sectionsToView, combination: combination, index: index, active: activeIndex === index, selected: !!selectedMap[index], onClick: onClick, onToggle: onToggle })
        : null; })));
};
export default CombinationsList;
