import _ from 'lodash';
var SESSION_ID = 'sessionId';
export var getSessionId = function () {
    var _a;
    return (_a = sessionStorage.getItem(SESSION_ID)) !== null && _a !== void 0 ? _a : '';
};
export var setSessionId = function (sessionId) {
    sessionStorage.setItem(SESSION_ID, sessionId);
};
export var removeSessionId = function () {
    sessionStorage.removeItem(SESSION_ID);
};
export function getIframeSessionId() {
    var MAX_MESSAGES = 3;
    var messageCount = 0;
    function parseEvent(_a) {
        var data = _a.data;
        if (!_.isString(data)) {
            return;
        }
        try {
            var _b = JSON.parse(data), messageId = _b.message_id, messageData = _b.data;
            if (messageId === 'session_id') {
                window.removeEventListener('message', parseEvent);
                var GMPWindow = window.open(window.location.href, '_blank');
                GMPWindow.sessionStorage.setItem(SESSION_ID, messageData.session_id);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error occurred during parsing a message: ', error);
        }
        finally {
            if (messageCount++ > MAX_MESSAGES) {
                window.removeEventListener('message', parseEvent);
            }
        }
    }
    // https://developer.veevavault.com/docs/#sending-session-ids-with-post-message
    window.addEventListener('message', parseEvent);
    window.parent.postMessage(JSON.stringify({ message_id: 'ready', data: {} }), document.referrer);
}
