import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AUTH_TYPE } from 'api/constants';
import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import { selectAuthError, selectAuthType, selectIsAuthenticated, selectIsAuthInProgress, signInDiscoveryRequest, signInRequest, } from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppRoute } from 'types';
import { authenticate, getSessionId } from 'utils';
import styles from './Home.module.scss';
var Home = function () {
    var sessionId = getSessionId();
    var searchParams = new URLSearchParams(window.location.search);
    var code = searchParams.get('code');
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _a = useState(''), username = _a[0], setUsername = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var isAuthenticated = useAppSelector(selectIsAuthenticated);
    var authType = useAppSelector(selectAuthType);
    var isAuthInProgress = useAppSelector(selectIsAuthInProgress);
    var authError = useAppSelector(selectAuthError);
    var onAuthDiscoverySubmitHandler = function (evt) {
        evt.preventDefault();
        dispatch(signInDiscoveryRequest(username));
    };
    var onLoginHandler = function (evt) {
        evt.preventDefault();
        var payload = {
            authType: AUTH_TYPE.PASSWORD,
            username: username,
            password: password,
        };
        dispatch(signInRequest(payload));
    };
    useEffect(function () {
        if (!sessionId) {
            if (code) {
                navigate(AppRoute.HOME, { replace: true });
                var payload = {
                    authType: AUTH_TYPE.SSO,
                    code: code,
                };
                dispatch(signInRequest(payload));
            }
            if (!code && authType && authType === AUTH_TYPE.SSO) {
                authenticate();
            }
        }
    }, [sessionId, authError, authType]);
    var content = React.createElement("div", null);
    if (!sessionId && !code) {
        content = (React.createElement("form", { className: "".concat(styles.form, " border rounded p-3"), onSubmit: onAuthDiscoverySubmitHandler },
            React.createElement("div", { className: "mb-3" },
                React.createElement("label", { htmlFor: "user", className: "form-label" }, "Enter email registered in Veeva"),
                React.createElement("input", { className: "form-control", id: "email", type: "email", value: username, onChange: function (e) {
                        setUsername(e.target.value);
                    } })),
            React.createElement(Button, { type: "submit", isDisabled: false, label: "Submit" })));
        if (authType && authType === AUTH_TYPE.PASSWORD) {
            content = (React.createElement("form", { className: "".concat(styles.form, " border rounded p-3"), onSubmit: onLoginHandler },
                React.createElement("div", { className: "mb-3" },
                    React.createElement("label", { htmlFor: "user", className: "form-label" }, "Enter email"),
                    React.createElement("input", { className: "form-control", id: "email", type: "email", value: username, onChange: function (e) {
                            setUsername(e.target.value);
                        } }),
                    React.createElement("label", { htmlFor: "password", className: "form-label" }, "Enter password"),
                    React.createElement("input", { className: "form-control", id: "password", type: "password", value: password, onChange: function (e) {
                            setPassword(e.target.value);
                        } })),
                authError ? (React.createElement("div", null, "Please check your credentials and try again")) : null,
                React.createElement(Button, { type: "submit", isDisabled: false, label: "Log In" })));
        }
    }
    if (isAuthenticated) {
        return React.createElement(Navigate, { to: AppRoute.FILTERS });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(NavBar, null),
        React.createElement("main", { className: 'd-flex flex-column align-items-center justify-content-center' }, isAuthInProgress ? React.createElement(LoadingSpinner, null) : content)));
};
export default Home;
