import React, { useEffect, useRef, useState } from 'react';
import { useBundle } from '../hooks/useBundle';
import styles from '../Modules.module.scss';
import { BundleListItemGroup } from './BundleListItemGroup';
import { BundleListItemModule } from './BundleListItemModule';
import BundleListTitle from './BundleListTitle';
import { GroupModal } from './GroupModal';
var BundleList = function (props) {
    var bundleIndex = props.bundleIndex;
    var _a = useBundle(bundleIndex), bundle = _a.bundle, toggleAll = _a.toggleAll, toggleModule = _a.toggleModule, toggleGroup = _a.toggleGroup, createGroup = _a.createGroup, removeGroup = _a.removeGroup, shiftModuleInGroup = _a.shiftModuleInGroup;
    var listRef = useRef(null);
    var lastGroupRef = useRef(null);
    var _b = useState(false), groupModalIsOpen = _b[0], setGroupModalIsOpen = _b[1];
    var groupsLengthRef = useRef((bundle === null || bundle === void 0 ? void 0 : bundle.groups) ? bundle.groups.length : -1);
    useEffect(function () {
        if (listRef.current) {
            listRef.current.scrollTop = 0;
        }
        groupsLengthRef.current = (bundle === null || bundle === void 0 ? void 0 : bundle.groups) ? bundle.groups.length : -1;
    }, [bundleIndex]);
    useEffect(function () {
        var groups = bundle === null || bundle === void 0 ? void 0 : bundle.groups;
        if (!groups) {
            groupsLengthRef.current = -1;
            return;
        }
        if (groupsLengthRef.current < groups.length && lastGroupRef.current) {
            lastGroupRef.current.scrollIntoView();
        }
        groupsLengthRef.current = groups.length;
    }, [bundle === null || bundle === void 0 ? void 0 : bundle.groups]);
    if (!bundle) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(BundleListTitle, { id: bundle.id, title: bundle.type, selectedNumber: bundle.selectedCount, totalNumber: bundle.availableCount, onChange: toggleAll, onCreateGroup: bundle.modules.length > 1
                ? function () { return setGroupModalIsOpen(true); }
                : undefined }),
        React.createElement("div", { className: "mb-3 ".concat(styles.moduleThumbnails), ref: listRef },
            bundle.modules.map(function (module) { return (React.createElement(BundleListItemModule, { key: module.index, module: module, onChange: function () { return toggleModule(module.index); }, checked: module.selected, disabled: module.disabled })); }),
            bundle.groups.map(function (group, idx) { return (React.createElement(BundleListItemGroup, { ref: (idx + 1) === bundle.groups.length ? lastGroupRef : undefined, key: group.index, group: group, checked: group.selected, onChange: function () { return toggleGroup(group.index); }, onRemove: function () { return removeGroup(group.index); }, onShitfModule: function (module, shift) { return shiftModuleInGroup(group.index, module, shift); } })); })),
        React.createElement(GroupModal, { isOpen: groupModalIsOpen, onClose: function () { return setGroupModalIsOpen(false); }, modules: bundle.modules, onCreate: createGroup })));
};
export default BundleList;
