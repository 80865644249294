var URL_KEYS = {
    TEMPLATE: 'template',
    BUNDLE: 'bundle',
};
export var getDocumentQueryString = function (_a) {
    var documentId = _a.documentId, majorVersion = _a.majorVersion, minorVersion = _a.minorVersion;
    return "".concat(documentId, "_").concat(majorVersion, "_").concat(minorVersion);
};
export var getDocumentFromQueryString = function (query) {
    if (!query) {
        throw new Error('splitDocumentQuery: Empty query string');
    }
    var _a = query.split('_'), documentId = _a[0], majorVersion = _a[1], minorVersion = _a[2];
    return { documentId: documentId, majorVersion: majorVersion, minorVersion: minorVersion };
};
export var makeQuery = function (_a) {
    var template = _a.template, bundle = _a.bundle;
    var urlParams = new URLSearchParams();
    urlParams.append(URL_KEYS.BUNDLE, getDocumentQueryString(bundle));
    urlParams.append(URL_KEYS.TEMPLATE, getDocumentQueryString(template));
    return urlParams.toString();
};
export var parseQuery = function (params) {
    var urlParams = new URLSearchParams(params);
    return {
        template: getDocumentFromQueryString(urlParams.get(URL_KEYS.TEMPLATE)),
        moduleBundle: getDocumentFromQueryString(urlParams.get(URL_KEYS.BUNDLE)),
    };
};
