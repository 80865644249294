import React, { useEffect, useRef } from 'react';
import Button from 'components/Button/Button';
import LayoutPreview from 'components/LayoutPreview';
import styles from '../Combinations.module.scss';
import { combinationGetModuleForSection, validateSectionForCombination } from '../utils';
export var COMBINATIONS_PREVIEW_TEST_ID = 'combination-preview';
var CombinationPreview = function (props) {
    var sections = props.sections, bundles = props.bundles, index = props.index, combination = props.combination, onRemove = props.onRemove;
    var containerRef = useRef(null);
    useEffect(function () {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [combination]);
    if (!combination) {
        return null;
    }
    return (React.createElement("div", { ref: containerRef, className: styles.container__middle, "data-testid": COMBINATIONS_PREVIEW_TEST_ID },
        React.createElement("div", { className: styles.container__middle__header },
            React.createElement("span", null,
                "Combination #",
                index + 1),
            React.createElement(Button, { type: "button", isDisabled: false, intent: "danger", label: "Remove Combination", onClick: onRemove })),
        React.createElement("div", { className: styles.container__middle__modules }, sections.map(function (item, idx) {
            var _a;
            if (!validateSectionForCombination(item)) {
                return null;
            }
            var layouts = ((_a = combinationGetModuleForSection(bundles, combination, idx)) !== null && _a !== void 0 ? _a : [])[0];
            if (!layouts) {
                return null;
            }
            return layouts.map(function (layout) { return (React.createElement("div", { key: "".concat(item.section.id, "-").concat(layout.index), className: styles.container__module },
                React.createElement(LayoutPreview, { documentId: layout.id, alt: layout.classification }))); });
        }))));
};
export default CombinationPreview;
