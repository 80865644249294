// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_Container__70pDi {
  height: 0;
  flex-grow: 1;
  display: flex;
  width: 100%;
  overflow: clip;
  position: relative;
}
.styles_Container__70pDi::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.styles_Container__70pDi::-webkit-scrollbar-thumb {
  background: #bec2c5;
}
.styles_Container__70pDi::-webkit-scrollbar-track {
  background: #e9ecef;
}
.styles_Container__70pDi {
  scrollbar-face-color: #bec2c5;
  scrollbar-track-color: #e9ecef;
}

.styles_LeftPanel__yo0Cm {
  width: 30%;
  overflow-y: auto;
  height: 100%;
}

.styles_RightPanel__TMW2g {
  display: flex;
  width: 70%;
  height: 100%;
}

.styles_Disclaimer__XF075 {
  background-color: #F5F5F5;
  padding: 16px 10px 5px 20px;
  border-bottom: 1px solid #dee2e6;
  font-size: 13px;
  z-index: 1000;
}

.styles_DisclaimerTitle__oCkGZ {
  font-size: 16px;
  font-weight: 600;
}

.styles_main__48czx {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/Simulation/styles.module.scss"],"names":[],"mappings":"AAsBA;EAEE,SAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;AAdF;AAZE;EACE,aAmBkB;EAlBlB,cAkBkB;AAJtB;AAXE;EACE,mBAc0B;AAD9B;AAVE;EACE,mBAUmC;AAEvC;AATE;EACE,6BAM0B;EAL1B,8BAKmC;AAMvC;;AAGA;EACE,UAAA;EACA,gBAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,UAAA;EACA,YAAA;AAAF;;AAGA;EACE,yBAAA;EACA,2BAAA;EACA,gCAAA;EACA,eAAA;EACA,aAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,YAAA;AAAF","sourcesContent":["@use '../../styles/_variables.module';\n\n@mixin scrollbars($size, $foreground-color, $background-color) {\n  &::-webkit-scrollbar {\n    width: $size;\n    height: $size;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background: $foreground-color;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: $background-color;\n  }\n\n  & {\n    scrollbar-face-color: $foreground-color;\n    scrollbar-track-color: $background-color;\n  }\n}\n\n.Container {\n  @include scrollbars(0.5rem, #bec2c5, #e9ecef);\n  height: 0;\n  flex-grow: 1;\n  display: flex;\n  width: 100%;\n  overflow: clip;\n  position: relative;\n}\n\n.LeftPanel {\n  width: 30%;\n  overflow-y: auto;\n  height: 100%;\n}\n\n.RightPanel {\n  display: flex;\n  width: 70%;\n  height: 100%;\n}\n\n.Disclaimer {\n  background-color: #F5F5F5;\n  padding: 16px 10px 5px 20px;\n  border-bottom: 1px solid variables.$borderColor;\n  font-size: 13px;\n  z-index: 1000;\n}\n\n.DisclaimerTitle {\n  font-size: 16px;\n  font-weight: 600;\n}\n\n.main {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearSectionButtonColor": `#6C757D`,
	"clearAllButtonPrimary": `#F8F9FA`,
	"clearAllButtonDisabled": `#A1BFBE`,
	"buttonBorderRadius": `3.2px`,
	"buttonFontSize": `14px`,
	"Container": `styles_Container__70pDi`,
	"LeftPanel": `styles_LeftPanel__yo0Cm`,
	"RightPanel": `styles_RightPanel__TMW2g`,
	"Disclaimer": `styles_Disclaimer__XF075`,
	"DisclaimerTitle": `styles_DisclaimerTitle__oCkGZ`,
	"main": `styles_main__48czx`
};
export default ___CSS_LOADER_EXPORT___;
