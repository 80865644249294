var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
var initialState = {
    isAuthenticated: false,
    authType: undefined,
    status: SAGA_STATUS.IDLE,
    error: '',
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        signInDiscoveryStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        signInDiscoverySuccess: function (state, action) {
            state.authType = action.payload;
            state.status = SAGA_STATUS.IDLE;
        },
        signInDiscoveryError: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.error = action.payload;
        },
        signInStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        signInSuccess: function (state) {
            state.isAuthenticated = true;
            state.status = SAGA_STATUS.IDLE;
        },
        signInFailure: function (state) {
            state.isAuthenticated = false;
            state.status = SAGA_STATUS.IDLE;
        },
        signInError: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.error = action.payload;
        },
        resetAuth: function () { return initialState; },
    },
});
export var signInRequest = createAction('signInRequest');
export var signInDiscoveryRequest = createAction('signInDiscoveryRequest');
export var selectIsAuthenticated = function (state) {
    return state.auth.isAuthenticated;
};
export var selectAuthType = function (state) { return state.auth.authType; };
export var selectIsAuthInProgress = function (state) {
    return state.auth.status === SAGA_STATUS.PENDING;
};
export var selectAuthError = function (state) { return state.auth.error; };
export var signInDiscoveryStart = (_a = authSlice.actions, _a.signInDiscoveryStart), signInDiscoverySuccess = _a.signInDiscoverySuccess, signInDiscoveryError = _a.signInDiscoveryError, signInStart = _a.signInStart, signInSuccess = _a.signInSuccess, signInFailure = _a.signInFailure, signInError = _a.signInError, resetAuth = _a.resetAuth;
export default authSlice.reducer;
