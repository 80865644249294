/* eslint-disable max-len */
import React from 'react';
var NoModuleBundle = function () {
    return (React.createElement("svg", { width: "105", height: "100", viewBox: "0 0 105 100" },
        React.createElement("path", { d: "M44.6033 99.4921C69.237 99.4921 89.2066 95.7607 89.2066 91.1579C89.2066 86.555 69.237 82.8237 44.6033 82.8237C19.9696 82.8237 0 86.555 0 91.1579C0 95.7607 19.9696 99.4921 44.6033 99.4921Z", fill: "#F5F5F7", fillOpacity: "0.8" }),
        React.createElement("path", { d: "M80.2856 66.6737L64.5455 47.3019C63.7902 46.3901 62.6863 45.8381 61.5238 45.8381H27.6817C26.5198 45.8381 25.4159 46.3901 24.6606 47.3019L8.92114 66.6737V76.7941H80.2863V66.6737H80.2856Z", fill: "#AEB8C2" }),
        React.createElement("path", { d: "M22.2566 20.8355H66.9493C67.6473 20.8355 68.3166 21.1128 68.8102 21.6063C69.3037 22.0998 69.5809 22.7692 69.5809 23.4671V84.8776C69.5809 85.5755 69.3037 86.2449 68.8102 86.7384C68.3166 87.2319 67.6473 87.5092 66.9493 87.5092H22.2566C21.5586 87.5092 20.8893 87.2319 20.3958 86.7384C19.9023 86.2449 19.625 85.5755 19.625 84.8776V23.4671C19.625 22.7692 19.9023 22.0998 20.3958 21.6063C20.8893 21.1128 21.5586 20.8355 22.2566 20.8355V20.8355Z", fill: "#F5F5F7" }),
        React.createElement("path", { d: "M28.0777 27.3835H61.1283C61.4773 27.3835 61.812 27.5222 62.0587 27.7689C62.3055 28.0157 62.4441 28.3504 62.4441 28.6993V45.1184C62.4441 45.4674 62.3055 45.8021 62.0587 46.0488C61.812 46.2956 61.4773 46.4342 61.1283 46.4342H28.0777C27.7287 46.4342 27.394 46.2956 27.1472 46.0488C26.9005 45.8021 26.7619 45.4674 26.7619 45.1184V28.6993C26.7619 28.3504 26.9005 28.0157 27.1472 27.7689C27.394 27.5222 27.7287 27.3835 28.0777 27.3835ZM28.25 53.577H60.9559C61.3506 53.577 61.7291 53.7338 62.0082 54.0128C62.2873 54.2919 62.4441 54.6704 62.4441 55.0651C62.4441 55.4598 62.2873 55.8383 62.0082 56.1174C61.7291 56.3965 61.3506 56.5533 60.9559 56.5533H28.25C27.8553 56.5533 27.4768 56.3965 27.1977 56.1174C26.9186 55.8383 26.7619 55.4598 26.7619 55.0651C26.7619 54.6704 26.9186 54.2919 27.1977 54.0128C27.4768 53.7338 27.8553 53.577 28.25 53.577ZM28.25 61.3158H60.9559C61.3507 61.3158 61.7293 61.4726 62.0085 61.7518C62.2876 62.0309 62.4444 62.4095 62.4444 62.8043C62.4444 63.199 62.2876 63.5776 62.0085 63.8568C61.7293 64.1359 61.3507 64.2928 60.9559 64.2928H28.25C27.8552 64.2928 27.4766 64.1359 27.1975 63.8568C26.9184 63.5776 26.7615 63.199 26.7615 62.8043C26.7615 62.4095 26.9184 62.0309 27.1975 61.7518C27.4766 61.4726 27.8552 61.3158 28.25 61.3158ZM80.1402 89.9355C79.6303 91.9559 77.8395 93.4618 75.7092 93.4618H13.4967C11.3665 93.4618 9.57567 91.9553 9.06646 89.9355C8.96934 89.5505 8.92029 89.1549 8.92041 88.7579V66.6743H26.2349C28.1474 66.6743 29.6888 68.2849 29.6888 70.2401V70.2664C29.6888 72.221 31.248 73.7993 33.1605 73.7993H56.0454C57.9579 73.7993 59.5171 72.2066 59.5171 70.2513V70.2434C59.5171 68.2881 61.0586 66.6737 62.9711 66.6737H80.2855V88.7585C80.2855 89.1645 80.2349 89.5585 80.1402 89.9355Z", fill: "#DCE0E6" }),
        React.createElement("path", { d: "M82.3164 21.9026L77.823 23.6461C77.7075 23.6909 77.5818 23.7023 77.4602 23.6789C77.3385 23.6555 77.226 23.5982 77.1355 23.5137C77.045 23.4292 76.9802 23.3208 76.9485 23.2011C76.9168 23.0813 76.9196 22.9551 76.9565 22.8368L78.2309 18.7533C76.5276 16.8164 75.5276 14.4546 75.5276 11.9059C75.5276 5.33026 82.1842 0 90.396 0C98.6059 0 105.263 5.33026 105.263 11.9059C105.263 18.4816 98.6065 23.8118 90.3954 23.8118C87.4164 23.8118 84.6427 23.1105 82.3164 21.9026Z", fill: "#DCE0E6" }),
        React.createElement("path", { d: "M96.2528 14.0559C97.2879 14.0559 98.1271 13.2268 98.1271 12.204C98.1271 11.1811 97.2879 10.352 96.2528 10.352C95.2176 10.352 94.3784 11.1811 94.3784 12.204C94.3784 13.2268 95.2176 14.0559 96.2528 14.0559Z", fill: "white" }),
        React.createElement("path", { d: "M86.4132 13.8243H82.6646L84.5711 10.5836L86.4132 13.8243ZM88.756 10.5836H92.0356V13.8243H88.756V10.5836Z", fill: "white" })));
};
export default NoModuleBundle;
