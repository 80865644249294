export var ProjectType;
(function (ProjectType) {
    ProjectType["BRAND_ALERT_MEDSCAPE"] = "Medscape";
    ProjectType["CARD"] = "Card";
    ProjectType["DISPLAY_PANEL"] = "DisplayPanel";
    ProjectType["EMAIL"] = "email";
    ProjectType["IPAD"] = "iPad";
    ProjectType["MOBILE_ALERT"] = "MobileAlert";
    ProjectType["MOBILE_ALERT_DOXIMITY"] = "Doximity";
    ProjectType["MOBILE_ALERT_EPOCRATES"] = "Epocrates";
    ProjectType["UNIVERSAL_ARTBOARD"] = "UniversalArtboard";
})(ProjectType || (ProjectType = {}));
