import dayjs from 'dayjs';
import { inflect } from 'inflection';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { Combobox } from 'react-widgets/esm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRecipeRequest, selectRecipeData, selectRecipeDataLoadingStatus, selectRecipeError, } from 'store/recipeSlice';
import { selectCurrentUserName, selectUserNames } from 'store/userSlice';
import Button from '../../../components/Button/Button';
import styles from './RecipeModal.module.scss';
var Backdrop = function (_a) {
    var onModalClose = _a.onModalClose;
    return React.createElement("div", { className: styles.backdrop, onClick: onModalClose, onKeyUp: function () { return undefined; } });
};
var ModalOverlay = function (_a) {
    var onModalClose = _a.onModalClose, permutations = _a.permutations, onRecipeSuccess = _a.onRecipeSuccess;
    var dispatch = useAppDispatch();
    var userNames = useAppSelector(selectUserNames);
    var currentUser = useAppSelector(selectCurrentUserName);
    var recipeLoading = useAppSelector(selectRecipeDataLoadingStatus);
    var recipeSuccess = useAppSelector(selectRecipeData);
    var recipeError = useAppSelector(selectRecipeError);
    var _b = useState(currentUser), owner = _b[0], setOwner = _b[1];
    var _c = useState(false), ownerError = _c[0], setOwnerError = _c[1];
    var onNumberChangeHandler = function (value) {
        setOwnerError(false);
        setOwner(value);
    };
    var onFormSubmitHandler = function (evt) {
        evt.preventDefault();
        setOwnerError(false);
        var ownerErr = !userNames.includes(owner);
        if (ownerErr) {
            setOwnerError(true);
            return;
        }
        dispatch(getRecipeRequest({
            owner: owner,
            reviewDate: dayjs().add(2, 'day').format('YYYY-MM-DD'),
            timestamp: dayjs().format('YYYY-MM-DDTHH-mm-ssZZ[Z]'),
            permutations: permutations,
        }));
    };
    useEffect(function () {
        if (recipeSuccess) {
            onModalClose();
            onRecipeSuccess === null || onRecipeSuccess === void 0 ? void 0 : onRecipeSuccess();
            toast("Selected combinations (".concat(permutations.length, ") have been sent to be built")
                + ' and should be completed in about 2 days.'
                + '\nYou will be notified via email when the building process is complete', { hideProgressBar: true });
        }
    }, [recipeSuccess]);
    return (React.createElement("div", { className: styles.modal },
        React.createElement("div", { className: styles.modalHeader },
            React.createElement("div", null,
                React.createElement("h1", { className: "fs-5" }, "Confirm Information Below"),
                React.createElement("p", { className: "mb-0" },
                    permutations.length,
                    " ",
                    inflect('combination', permutations.length))),
            React.createElement("button", { type: "button", className: "btn-close", "aria-label": "Close", onClick: onModalClose })),
        React.createElement("div", { className: styles.modalBody },
            React.createElement("div", { className: styles.alert }, "Once the combinations have been confirmed, you cannot undo it."),
            React.createElement("form", { className: styles.form, onSubmit: onFormSubmitHandler, id: "recipe-form" },
                React.createElement("div", { className: styles.ownerContainer },
                    React.createElement("label", { htmlFor: "owner_input", className: styles.label }, "Owner"),
                    React.createElement(Combobox, { data: userNames, filter: "contains", value: owner, onChange: onNumberChangeHandler, id: "owner", containerClassName: ownerError ? 'input-error' : '', disabled: recipeLoading }),
                    ownerError ? (React.createElement("div", { className: styles.error }, "Specified user doesn\u2019t exist. Select an owner from the list.")) : (React.createElement("div", { className: styles.tip }, "Specify yourself or another person as an owner"))))),
        React.createElement("div", { className: styles.modalFooter },
            recipeError && (React.createElement("div", { className: styles.networkError }, "Something has gone wrong. Please try again in 30 seconds")),
            React.createElement(Button, { type: "submit", isDisabled: false, label: "Build", styleOverride: { width: '105px' }, formId: "recipe-form", isLoading: recipeLoading }))));
};
var RecipeModal = function (_a) {
    var onModalClose = _a.onModalClose, permutations = _a.permutations, onRecipeSuccess = _a.onRecipeSuccess;
    return (React.createElement(React.Fragment, null,
        ReactDOM.createPortal(React.createElement(Backdrop, { onModalClose: onModalClose }), document.getElementById('backdrop-root')),
        ReactDOM.createPortal(React.createElement(ModalOverlay, { onModalClose: onModalClose, permutations: permutations, onRecipeSuccess: onRecipeSuccess }), document.getElementById('modal-root'))));
};
export default RecipeModal;
