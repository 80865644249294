import classNames from 'classnames';
import React, { useState } from 'react';
import CheckIcon from 'assets/images/Check.svg';
import CopyIcon from 'assets/images/Copy.svg';
import styles from './styles.module.scss';
var CopyClipboardIcon = function (_a) {
    var text = _a.text;
    var _b = useState(false), clicked = _b[0], setClicked = _b[1];
    var copyClipboardClick = function (event) {
        event.stopPropagation();
        void navigator.clipboard.writeText(text);
        setClicked(true);
        setTimeout(function () { return setClicked(false); }, 2000);
    };
    return (React.createElement("button", { className: classNames([styles.buttonBg, 'btn btn-sm py-1 rounded-0']), onClick: copyClipboardClick, disabled: clicked }, clicked ? React.createElement(CheckIcon, { className: 'h-100' })
        : React.createElement(CopyIcon, { className: 'h-100' })));
};
export default CopyClipboardIcon;
