var BoundingBox = /** @class */ (function () {
    function BoundingBox(top, bottom) {
        if (top === void 0) { top = 0; }
        if (bottom === void 0) { bottom = 0; }
        this.top = top;
        this.bottom = bottom;
    }
    BoundingBox.prototype.shift = function (delta) {
        return new BoundingBox(this.top + delta, this.bottom + delta);
    };
    BoundingBox.prototype.contains = function (other) {
        return this.top < other.top && this.bottom > other.bottom;
    };
    return BoundingBox;
}());
export default BoundingBox;
