import classNames from 'classnames';
import React from 'react';
import ChevronLeft from 'assets/images/ChevronLeft.svg';
import ChevronRight from 'assets/images/ChevronRight.svg';
import CopyClipboardIcon from './CopyClipboardIcon';
import styles from './styles.module.scss';
var ActionPanel = function (_a) {
    var _b;
    var displayName = _a.displayName, layoutVariants = _a.layoutVariants, layoutVariantIdx = _a.layoutVariantIdx, onChangeLayout = _a.onChangeLayout;
    if (!layoutVariants.length) {
        return null;
    }
    var prev = layoutVariants[layoutVariantIdx === 0 ? (layoutVariants.length - 1) : layoutVariantIdx - 1];
    var next = layoutVariants[layoutVariantIdx === (layoutVariants.length - 1) ? 0 : layoutVariantIdx + 1];
    var handleArrowClick = function (event, layout) {
        event.stopPropagation();
        if (layoutVariants.length > 1) {
            onChangeLayout(layout);
        }
    };
    var assetNumber = (_b = layoutVariants[layoutVariantIdx]) === null || _b === void 0 ? void 0 : _b.number;
    return (React.createElement("div", { className: classNames(styles.actionPanel, 'shadow'), "data-testid": "simulation-section-action-panel" },
        React.createElement("div", { className: "display-name bg-white fw-bold", style: { minWidth: 150 } },
            displayName,
            assetNumber &&
                React.createElement("span", { className: "fw-light d-flex align-items-center justify-content-between" },
                    React.createElement("span", { className: 'pe-2' }, assetNumber),
                    React.createElement(CopyClipboardIcon, { text: assetNumber }))),
        layoutVariantIdx < 0 ? (React.createElement("button", { className: "btn btn-light", onClick: function (event) {
                event.stopPropagation();
                onChangeLayout(layoutVariants[0]);
            } }, "View Modular Content")) : (React.createElement(React.Fragment, null,
            React.createElement("button", { onClick: prev && (function (event) { return handleArrowClick(event, prev); }), className: "btn btn-light", type: "button" },
                React.createElement(ChevronLeft, null)),
            React.createElement("div", { className: "bg-white d-flex align-items-center" },
                React.createElement("div", null, "".concat((layoutVariantIdx + 1), "/").concat(layoutVariants.length))),
            React.createElement("button", { onClick: next && (function (event) { return handleArrowClick(event, next); }), className: "btn btn-light", type: "button" },
                React.createElement(ChevronRight, null))))));
};
export default ActionPanel;
