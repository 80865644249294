import EventEmitter from 'events';
export var EVENTS;
(function (EVENTS) {
    EVENTS["EXPAND_ACCORDION"] = "expand.accordion";
    EVENTS["FOCUS_SECTION_RIGHT"] = "focus.section-right";
    EVENTS["SCROLL_SECTION_RIGHT"] = "scroll.section-right";
})(EVENTS || (EVENTS = {}));
export var eventEmitter = new EventEmitter();
// eslint-disable-next-line jest/require-hook
eventEmitter.setMaxListeners(1000); // by default 10
