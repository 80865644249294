import React from 'react';
import { Combobox } from 'react-widgets/esm';
import Button from 'components/Button/Button';
import { selectCountriesNames, selectProductFamiliesNames, selectResourceTypesNames, selectDeliveryMethodsNames, selectDeploymentSuppliersNames, selectIndependentFiltersLoading, selectResourceTypesLoading, } from 'store/filtersSlice';
import { useAppSelector } from 'store/hooks';
import styles from '../Filters.module.scss';
import { useFilters } from '../useFilters';
export var submitButtonTestId = 'submittButtonTestId';
var FilterBar = function (_a) {
    var onJobZeroRequestSubmitted = _a.onJobZeroRequestSubmitted;
    var productFamilies = useAppSelector(selectProductFamiliesNames);
    var countries = useAppSelector(selectCountriesNames);
    var resourceTypes = useAppSelector(selectResourceTypesNames);
    var deliveryMethods = useAppSelector(selectDeliveryMethodsNames);
    var deploymentSuppliers = useAppSelector(selectDeploymentSuppliersNames);
    var independentFiltersLoading = useAppSelector(selectIndependentFiltersLoading);
    var resourceTypesLoading = useAppSelector(selectResourceTypesLoading);
    var _b = useFilters(onJobZeroRequestSubmitted), country = _b.country, productFamily = _b.productFamily, resourceType = _b.resourceType, deliveryMethod = _b.deliveryMethod, deploymentSupplier = _b.deploymentSupplier, isButtonDisabled = _b.isButtonDisabled, onCountryChangeHandler = _b.onCountryChangeHandler, onProductFamilyChangeHandler = _b.onProductFamilyChangeHandler, onResourceTypeChangeHandler = _b.onResourceTypeChangeHandler, onDeliveryMethodChangeHandler = _b.onDeliveryMethodChangeHandler, onDeploymentSupplierChangeHandler = _b.onDeploymentSupplierChangeHandler, onFormSubmitHandler = _b.onFormSubmitHandler;
    return (React.createElement("form", { className: styles.form, onSubmit: onFormSubmitHandler },
        React.createElement(Combobox, { busy: independentFiltersLoading, data: productFamilies, filter: "contains", placeholder: "Product*", value: productFamily, onChange: onProductFamilyChangeHandler }),
        React.createElement(Combobox, { busy: independentFiltersLoading, data: countries, filter: "contains", placeholder: "Country*", value: country, onChange: onCountryChangeHandler }),
        React.createElement(Combobox, { busy: resourceTypesLoading, data: resourceTypes, filter: "contains", placeholder: "Resource type*", value: resourceType, onChange: onResourceTypeChangeHandler, disabled: !country }),
        React.createElement(Combobox, { busy: independentFiltersLoading, data: deliveryMethods, filter: "contains", placeholder: "Method of delivery", value: deliveryMethod, onChange: onDeliveryMethodChangeHandler }),
        React.createElement(Combobox, { busy: independentFiltersLoading, data: deploymentSuppliers, filter: "contains", placeholder: "Deployment supplier", value: deploymentSupplier, onChange: onDeploymentSupplierChangeHandler }),
        React.createElement(Button, { type: "submit", isDisabled: isButtonDisabled, label: "Filter", styleOverride: { width: '100%' }, dataTestId: submitButtonTestId })));
};
export default FilterBar;
