var _a;
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
export var initialState = {
    independentFilters: {
        filters: {
            countries: [],
            productFamilies: [],
            deliveryMethods: [],
            deploymentSuppliers: [],
        },
        status: SAGA_STATUS.IDLE,
        error: '',
    },
    dependentFilters: {
        filters: {
            resourceTypes: {
                currentCountry: '',
                data: [],
            },
        },
        status: SAGA_STATUS.IDLE,
        error: '',
    },
    selectedFilters: {
        selectedCountry: '',
        selectedProductFamily: '',
        selectedResourceType: '',
        selectedDeliveryMethod: '',
        selectedDeploymentSupplier: '',
    },
};
export var filtersSlice = createSlice({
    name: 'filters',
    initialState: initialState,
    reducers: {
        getIndependentFiltersDataStart: function (state) {
            state.independentFilters.status = SAGA_STATUS.PENDING;
            state.independentFilters.error = '';
        },
        getIndependentFiltersDataSuccess: function (state, action) {
            state.independentFilters.status = SAGA_STATUS.IDLE;
            state.independentFilters.filters = action.payload;
        },
        getIndependentFiltersDataError: function (state, action) {
            state.independentFilters.status = SAGA_STATUS.IDLE;
            state.independentFilters.error = action.payload;
        },
        getResourceTypeFilterDataStart: function (state) {
            state.dependentFilters.status = SAGA_STATUS.PENDING;
            state.dependentFilters.error = '';
        },
        getResourceTypeFilterDataSuccess: function (state, action) {
            state.dependentFilters.status = SAGA_STATUS.IDLE;
            state.dependentFilters.filters = action.payload;
        },
        getResourceTypeFilterDataError: function (state, action) {
            state.dependentFilters.status = SAGA_STATUS.IDLE;
            state.dependentFilters.error = action.payload;
        },
        setSelectedFilters: function (state, action) {
            state.selectedFilters = action.payload;
        },
        resetFilters: function () { return initialState; },
    },
});
export var getIndependentFiltersDataRequest = createAction('getIndependentFiltersDataRequest');
export var getResourceTypeFilterDataRequest = createAction('getResourceTypeFilterDataRequest');
export var selectIndependentFiltersLoading = function (state) {
    return state.filters.independentFilters.status === SAGA_STATUS.PENDING;
};
export var selectResourceTypesLoading = function (state) {
    return state.filters.dependentFilters.status === SAGA_STATUS.PENDING;
};
export var selectIndependentFiltersError = function (state) {
    return state.filters.independentFilters.error;
};
export var selectResourceTypesError = function (state) {
    return state.filters.dependentFilters.error;
};
export var selectSelectedFilters = function (state) {
    return state.filters.selectedFilters;
};
var selectCountries = function (state) {
    return state.filters.independentFilters.filters.countries;
};
export var selectCountriesNames = createSelector([selectCountries], function (countries) { return countries.map(function (country) { return country.name__v; }); });
export var selectCountryIdByName = function (state, countryName) {
    var countries = state.filters.independentFilters.filters.countries.filter(function (country) { return country.name__v === countryName; });
    return countries.length ? countries[0].id : '';
};
export var selectProductIdByName = function (state, productName) {
    var products = state.filters.independentFilters.filters.productFamilies;
    return products.filter(function (product) { return product.name__v === productName; })[0].id;
};
export var selectResourceTypeIdByName = function (state, resourceTypeName) {
    var resourceTypes = state.filters.dependentFilters.filters.resourceTypes.data;
    return resourceTypes.filter(function (resourceType) { return resourceType.name__v === resourceTypeName; })[0].id;
};
export var selectDeploymentSupplierIdByName = function (state, deploymentSupplierName) {
    var deploymentSuppliers = state.filters.independentFilters.filters.deploymentSuppliers;
    return deploymentSuppliers.filter(function (deploymentSupplier) { return deploymentSupplier.name__v === deploymentSupplierName; })[0].id;
};
var selectProductFamilies = function (state) {
    return state.filters.independentFilters.filters.productFamilies;
};
export var selectProductFamiliesNames = createSelector([selectProductFamilies], function (productFamilies) { return productFamilies.map(function (productFamily) { return productFamily.name__v; }); });
var selectDeliveryMethods = function (state) {
    return state.filters.independentFilters.filters.deliveryMethods;
};
export var selectDeliveryMethodsNames = createSelector([selectDeliveryMethods], function (deliveryMethods) { return deliveryMethods.map(function (deliveryMethod) { return deliveryMethod.label; }); });
var selectDeploymentSuppliers = function (state) {
    return state.filters.independentFilters.filters.deploymentSuppliers;
};
export var selectDeploymentSuppliersNames = createSelector([selectDeploymentSuppliers], function (deploymentSuppliers) {
    return deploymentSuppliers.map(function (deploymentSupplier) { return deploymentSupplier.name__v; });
});
var selectResourceTypes = function (state) {
    return state.filters.dependentFilters.filters.resourceTypes.data;
};
export var selectResourceTypesNames = createSelector([selectResourceTypes], function (resourceTypes) { return resourceTypes.map(function (resourceType) { return resourceType.name__v; }); });
export var getIndependentFiltersDataStart = (_a = filtersSlice.actions, _a.getIndependentFiltersDataStart), getIndependentFiltersDataSuccess = _a.getIndependentFiltersDataSuccess, getIndependentFiltersDataError = _a.getIndependentFiltersDataError, getResourceTypeFilterDataStart = _a.getResourceTypeFilterDataStart, getResourceTypeFilterDataSuccess = _a.getResourceTypeFilterDataSuccess, getResourceTypeFilterDataError = _a.getResourceTypeFilterDataError, setSelectedFilters = _a.setSelectedFilters, resetFilters = _a.resetFilters;
export default filtersSlice.reducer;
