import { capitalize } from 'inflection';
import React from 'react';
import styles from './Button.module.scss';
var Button = function (props) {
    var _a = props.isDisabled, isDisabled = _a === void 0 ? false : _a, label = props.label, styleOverride = props.styleOverride, _b = props.intent, intent = _b === void 0 ? 'primary' : _b, type = props.type, onClick = props.onClick, formId = props.formId, isLoading = props.isLoading, _c = props.dataTestId, dataTestId = _c === void 0 ? '' : _c, _d = props.className, className = _d === void 0 ? '' : _d;
    var buttonIntentClass = "button".concat(capitalize(intent));
    return (React.createElement("button", { type: type, className: "".concat(styles.button, " ").concat(styles[buttonIntentClass], " ").concat(className), disabled: isDisabled, style: styleOverride, onClick: onClick, form: formId, "data-testid": dataTestId }, isLoading ? (React.createElement("div", { className: "spinner-border spinner-border-sm text-light", role: "status" },
        React.createElement("span", { className: "visually-hidden" }, "Loading..."))) : (label)));
};
export default Button;
