import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
var Loading = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(NavBar, null),
        React.createElement("main", { className: 'd-flex flex-column align-items-center justify-content-center' },
            React.createElement(LoadingSpinner, null))));
};
export default Loading;
