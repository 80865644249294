var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, put, call, select, takeLatest, } from 'redux-saga/effects';
import { getCountriesFromVeeva, getProductFamiliesFromVeeva, getDeliveryMethodsFromVeeva, getDeploymentSuppliersFromVeeva, getResourceTypeByCountryFromVeeva, } from 'api';
import { checkAndFormatAxiosError } from 'api/error';
import { getSessionId } from 'utils';
import { getIndependentFiltersDataRequest, getIndependentFiltersDataStart, getIndependentFiltersDataSuccess, getIndependentFiltersDataError, getResourceTypeFilterDataRequest, getResourceTypeFilterDataStart, getResourceTypeFilterDataSuccess, getResourceTypeFilterDataError, selectCountryIdByName, } from './filtersSlice';
function independentFiltersSaga() {
    var sessionId, _a, countries, productFamilies, deliveryMethods, deploymentSuppliers, err_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, put(getIndependentFiltersDataStart())];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(getSessionId)];
            case 2:
                sessionId = _b.sent();
                return [4 /*yield*/, all([
                        call(getCountriesFromVeeva, sessionId),
                        call(getProductFamiliesFromVeeva, sessionId),
                        call(getDeliveryMethodsFromVeeva, sessionId),
                        call(getDeploymentSuppliersFromVeeva, sessionId),
                    ])];
            case 3:
                _a = _b.sent(), countries = _a[0].data.data, productFamilies = _a[1].data.data, deliveryMethods = _a[2].data.data, deploymentSuppliers = _a[3].data.data;
                return [4 /*yield*/, put(getIndependentFiltersDataSuccess({
                        countries: countries,
                        productFamilies: productFamilies,
                        deliveryMethods: deliveryMethods,
                        deploymentSuppliers: deploymentSuppliers,
                    }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                err_1 = _b.sent();
                error = checkAndFormatAxiosError(err_1);
                return [4 /*yield*/, put(getIndependentFiltersDataError(error instanceof Error ? error.message : JSON.stringify(error)))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function resourceTypeByCountrySaga(_a) {
    var sessionId, countryId, data, err_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 8]);
                return [4 /*yield*/, put(getResourceTypeFilterDataStart())];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(getSessionId)];
            case 2:
                sessionId = _b.sent();
                return [4 /*yield*/, select(selectCountryIdByName, payload)];
            case 3:
                countryId = _b.sent();
                return [4 /*yield*/, call(getResourceTypeByCountryFromVeeva, sessionId, countryId)];
            case 4:
                data = (_b.sent()).data.data;
                return [4 /*yield*/, put(getResourceTypeFilterDataSuccess({
                        resourceTypes: {
                            currentCountry: payload,
                            data: data,
                        },
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                err_2 = _b.sent();
                error = checkAndFormatAxiosError(err_2);
                return [4 /*yield*/, put(getResourceTypeFilterDataError(error instanceof Error ? error.message : JSON.stringify(error)))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export default [
    takeLatest(getIndependentFiltersDataRequest.type, independentFiltersSaga),
    takeLatest(getResourceTypeFilterDataRequest.type, resourceTypeByCountrySaga),
];
