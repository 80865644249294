export * from './ProjectsConfig';
export * from './ProjectType';
export * from './PreviewOptionName';
export var DEFAULT_SECTION_WIDTH = 600;
export var ChannelType;
(function (ChannelType) {
    ChannelType["EMAIL"] = "Email";
    ChannelType["ALERT"] = "Alert - Mobile/Brand";
    ChannelType["AD"] = "Banner/Digital Ad";
})(ChannelType || (ChannelType = {}));
export var SAGA_STATUS;
(function (SAGA_STATUS) {
    SAGA_STATUS["PENDING"] = "pending";
    SAGA_STATUS["IDLE"] = "idle";
})(SAGA_STATUS || (SAGA_STATUS = {}));
export var DEFAULT_CLASSIFICATION = 'unclassified';
export var CLEAR_ZONE_BUTTON = 'Clear Zone';
