var _this = this;
import _ from 'lodash';
import React, { useRef, useEffect, useMemo } from 'react';
import AccordionPanel from 'components/Accordion/AccordionPanel';
import LayoutPreviewList from 'components/LayoutPreviewList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleActivePanels, selectActivePanels, selectTemplateReplacedLayouts, selectScreens, } from 'store/simulationSlice';
import BoundingBox from 'utils/BoundingBox';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
var getBoundingBox = function (element) {
    var _a;
    var _b = (_a = element === null || element === void 0 ? void 0 : element.getBoundingClientRect()) !== null && _a !== void 0 ? _a : { top: 0, height: 0 }, top = _b.top, height = _b.height;
    return new BoundingBox(top, top + height);
};
var Accordion = function (_a) {
    var screens = _a.screens, scrollContainer = _a.scrollContainer, onClearButtonClick = _a.onClearButtonClick, _b = _a.onItemClick, onItemClick = _b === void 0 ? Function.prototype : _b;
    var activePanels = useAppSelector(selectActivePanels);
    var templateReplacedLayouts = useAppSelector(selectTemplateReplacedLayouts);
    var templateScreens = useAppSelector(selectScreens);
    var templateLayouts = useMemo(function () { return _(templateScreens).flatMap('sections').flatMap('layouts').value(); }, [templateScreens]);
    var dispatch = useAppDispatch();
    var panelsWithItems = useRef({});
    useEffect(function () {
        var expandPanel = function (screenId, layoutId) {
            dispatch(toggleActivePanels({ screenId: screenId, activeState: true }));
            setTimeout(function () {
                var _a, _b;
                var data = (_a = panelsWithItems.current) === null || _a === void 0 ? void 0 : _a[screenId];
                var panel = data === null || data === void 0 ? void 0 : data.panel;
                if (!panel) {
                    return;
                }
                var item = layoutId && ((_b = data === null || data === void 0 ? void 0 : data.items) === null || _b === void 0 ? void 0 : _b[layoutId]);
                if (!item) {
                    return panel.scrollIntoView({ behavior: 'smooth' });
                }
                var containerRect = getBoundingBox(scrollContainer === null || scrollContainer === void 0 ? void 0 : scrollContainer.current);
                var itemRect = getBoundingBox(item);
                if (containerRect.contains(itemRect)) {
                    return;
                }
                var panelRect = getBoundingBox(panel);
                var shift = containerRect.top - panelRect.top;
                if (shift > 0 && containerRect.contains(itemRect.shift(shift))) {
                    panel.scrollIntoView({ behavior: 'smooth' });
                }
                else {
                    item.scrollIntoView({ behavior: 'smooth' });
                }
            });
        };
        eventEmitter.addListener(EVENTS.EXPAND_ACCORDION, expandPanel);
        return function () {
            eventEmitter.removeListener(EVENTS.EXPAND_ACCORDION, expandPanel);
        };
    }, []);
    var onClickPanel = function (sectionId, screenId, activeState) {
        dispatch(toggleActivePanels({ screenId: screenId, activeState: activeState }));
        eventEmitter.emit(EVENTS.SCROLL_SECTION_RIGHT, sectionId);
    };
    var getCurrentSectionId = function (sectionIds) {
        var relatedSectionId = sectionIds.find(function (sectionId) { return templateReplacedLayouts[sectionId]; });
        return relatedSectionId !== null && relatedSectionId !== void 0 ? relatedSectionId : sectionIds[0];
    };
    var isClearButtonShown = function (layouts) {
        return templateLayouts.some(function (templateLayout) { return layouts.some(function (layout) { return templateLayout.id === layout.id; }); });
    };
    return (React.createElement(React.Fragment, null, _.map(screens, function (screen) { return (React.createElement(AccordionPanel, { key: screen.id, ref: function (panel) {
            panelsWithItems.current[screen.id] = panelsWithItems.current[screen.id] || {};
            panelsWithItems.current[screen.id].panel = panel;
        }, sectionId: screen.sectionIds[0], isActive: activePanels[screen.id], isClearButtonShown: isClearButtonShown(screen.layouts), label: screen.displayName, onClick: function () { return onClickPanel(getCurrentSectionId(screen.sectionIds), screen.id); }, onClearButtonClick: function () { return onClearButtonClick(screen); } },
        React.createElement(LayoutPreviewList, { ref: function (items) {
                panelsWithItems.current[screen.id] = panelsWithItems.current[screen.id] || {};
                panelsWithItems.current[screen.id].items = items !== null && items !== void 0 ? items : {};
            }, layouts: screen.layouts, onItemClick: onItemClick.bind(_this, screen) }))); })));
};
export default Accordion;
