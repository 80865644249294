// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModulesModal_backdrop__GOAav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: black;
  opacity: 0.3;
}

.ModulesModal_modal__ddS\\+s {
  display: block;
  background-color: white;
  margin: auto;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  min-width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100vh - 1rem);
  overflow: auto;
}
.ModulesModal_modalBody__qkl\\+0 {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: calc(24px + 0.4rem);
}
.ModulesModal_modalBody__qkl\\+0 .ModulesModal_closeButton__7BO4d {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.ModulesModal_modalBody__qkl\\+0 nav {
  width: calc(100% - 48px - 0.8rem);
}`, "",{"version":3,"sources":["webpack://./src/components/ModulesModal.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,cAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,oCAAA;EACA,oBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,0BAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,4BAAA;AACR;AACQ;EACI,kBAAA;EACA,WAAA;EACA,aAAA;AACZ;AAEQ;EACI,iCAAA;AAAZ","sourcesContent":[".backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 10;\n    background-color: black;\n    opacity: 0.3;\n}\n\n.modal {\n    display: block;\n    background-color: white;\n    margin: auto;\n    z-index: 100;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    border-radius: 4.8px;\n    min-width: 80%;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    height: calc(100vh - 1rem);\n    overflow: auto;\n\n    &Body {\n        display: flex;\n        flex-direction: column;\n        height: 100%;\n        position: relative;\n        padding: calc(24px + 0.4rem);\n\n        .closeButton {\n            position: absolute;\n            top: 0.2rem;\n            right: 0.2rem;\n        }\n\n        nav {\n            width: calc(100% - 48px - 0.8rem);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ModulesModal_backdrop__GOAav`,
	"modal": `ModulesModal_modal__ddS+s`,
	"modalBody": `ModulesModal_modalBody__qkl+0`,
	"closeButton": `ModulesModal_closeButton__7BO4d`
};
export default ___CSS_LOADER_EXPORT___;
