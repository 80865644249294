var _a;
import { OAUTH2_URL } from 'api/constants';
var stage = (_a = process.env.REACT_APP_STAGE) !== null && _a !== void 0 ? _a : 'dev';
export var authenticate = function () {
    var redirect = window.location.host === 'localhost:3000'
        ? window.location.origin.replace('localhost', '127.0.0.1')
        : window.location.origin;
    var searchParams = new URLSearchParams({
        redirect_uri: redirect,
        response_type: 'code',
        client_id: 'PERMUTATIONSBUILDERUI',
        state: 'wnv1QYLnvidky4u6XbPVaXb5nPlZPMII5z32cMatBfg',
        code_challenge: 'SCSQXXAME6RE9Q3UOW0LITXYWABR8RBKKM2I39PT333',
        code_challenge_method: 'plain',
    });
    window.location.assign("".concat(OAUTH2_URL[stage], "?").concat(searchParams.toString()));
};
