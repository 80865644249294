import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from 'components/NavBar/NavBar';
import { getIndependentFiltersDataRequest, selectIndependentFiltersError, selectResourceTypesError, resetFilters, setSelectedFilters, } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { reset as resetJobZero, selectJobZeroData, selectJobZeroDataLoadingStatus, selectLastSelectedDocumentId, setLastSelectedDocumentId, } from 'store/jobZeroSlice';
import FilterBar from './components/FilterBar';
import InitialTableContent from './components/InitialTableContent';
import JobZeroContent from './components/JobZeroContent';
import NoContentFound from './components/NoContentFound';
import Spinner from './components/Spinner';
import styles from './Filters.module.scss';
import { useLoadNextPage } from './useLoadNextPage';
var Filters = function () {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var independentFiltersError = useAppSelector(selectIndependentFiltersError);
    var resourceTypesError = useAppSelector(selectResourceTypesError);
    var filtersError = independentFiltersError || resourceTypesError;
    var jobZeroData = useAppSelector(selectJobZeroData);
    var jobZeroDataLoading = useAppSelector(selectJobZeroDataLoadingStatus);
    var lastSelectedDocumentId = useAppSelector(selectLastSelectedDocumentId);
    var _a = useState(false), isJobZeroRequestSubmitted = _a[0], setIsJobZeroRequestSubmitted = _a[1];
    var shouldDisplayJobZeroDocuments = jobZeroData && jobZeroData.length > 0;
    var lastSelectedDocumentRef = useRef(null);
    var _b = useLoadNextPage(shouldDisplayJobZeroDocuments, jobZeroDataLoading), observerTarget = _b.observerTarget, isNextPageLoading = _b.isNextPageLoading;
    var resetFiltersPage = useCallback(function () {
        dispatch(resetJobZero());
        dispatch(setSelectedFilters({
            selectedCountry: '',
            selectedProductFamily: '',
            selectedResourceType: '',
            selectedDeliveryMethod: '',
            selectedDeploymentSupplier: '',
        }));
        setIsJobZeroRequestSubmitted(false);
    }, [dispatch]);
    var onJobZeroSelectHandler = function (jobZeroId, moduleBundleId) {
        dispatch(setLastSelectedDocumentId(jobZeroId));
        navigate("/modules?jobzero=".concat(jobZeroId, "&bundle=").concat(moduleBundleId));
    };
    useEffect(function () {
        if (lastSelectedDocumentRef.current) {
            lastSelectedDocumentRef.current.scrollIntoView({ block: 'end' });
        }
    }, [lastSelectedDocumentRef.current]);
    useEffect(function () {
        dispatch(getIndependentFiltersDataRequest());
    }, [dispatch]);
    if (filtersError) {
        dispatch(resetFilters());
        dispatch(resetJobZero());
    }
    var tableContent = React.createElement(InitialTableContent, null);
    if (jobZeroDataLoading && !isNextPageLoading) {
        tableContent = React.createElement(Spinner, null);
    }
    if (isJobZeroRequestSubmitted &&
        !jobZeroDataLoading &&
        jobZeroData &&
        jobZeroData.length < 1) {
        tableContent = React.createElement(NoContentFound, null);
    }
    if (shouldDisplayJobZeroDocuments) {
        tableContent = (React.createElement(JobZeroContent, { ref: lastSelectedDocumentRef, jobZeroData: jobZeroData, lastSelectedDocumentId: lastSelectedDocumentId, onJobZeroSelectHandler: onJobZeroSelectHandler }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(NavBar, { onBrandClick: resetFiltersPage }),
        React.createElement("main", { className: "container-fluid mx-lg-5 px-3 ".concat(styles.contentContainer) },
            React.createElement("h4", { className: "p-0 pb-3 m-0 border-bottom", style: { width: 'auto' } }, "Select Criteria to Find Modular Content"),
            React.createElement("div", { className: "d-flex pt-4" },
                React.createElement(FilterBar, { onJobZeroRequestSubmitted: setIsJobZeroRequestSubmitted }),
                React.createElement("div", { className: "ms-4 flex-grow-1 ".concat(styles.tableBlock) },
                    React.createElement("table", { className: "table ".concat(styles.table) },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Document Number"),
                                React.createElement("th", null, "Name"),
                                React.createElement("th", null, "Module Bundle"))),
                        React.createElement("tbody", null, tableContent),
                        React.createElement("tfoot", null,
                            React.createElement("tr", { ref: observerTarget }))),
                    isNextPageLoading && (React.createElement("div", { className: 'd-flex align-items-center mt-2' },
                        React.createElement("div", { className: 'spinner-border text-secondary', role: 'status' }),
                        React.createElement("div", { className: 'ms-2' }, "Loading"))))))));
};
export default Filters;
