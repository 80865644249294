import _ from 'lodash';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import Screen from 'components/Screen';
import styles from './styles.module.scss';
var Artboard = forwardRef(function (_a, ref) {
    var screensRefs = _a.screensRefs, screens = _a.screens, onScroll = _a.onScroll, onCnahgeLayoutVariant = _a.onCnahgeLayoutVariant;
    var scrollContainerRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, function () { return scrollContainerRef.current; }, []);
    return (React.createElement("div", { ref: scrollContainerRef, className: styles.artboard, onScroll: onScroll },
        React.createElement("div", { className: styles.placeholder }),
        _.map(screens, function (_a) {
            var id = _a.id, sections = _a.sections;
            return (React.createElement(Screen, { key: id, ref: function (node) {
                    screensRefs.current[id] = node;
                }, screenId: id, sections: sections, onChangeLayoutVariant: onCnahgeLayoutVariant, scrollContainer: scrollContainerRef }));
        })));
});
Artboard.displayName = 'Artboard';
export default Artboard;
