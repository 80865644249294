export var AppRoute;
(function (AppRoute) {
    AppRoute["HOME"] = "/";
    AppRoute["FILTERS"] = "/filters";
    AppRoute["MODULES"] = "/modules";
    AppRoute["COMBINATIONS"] = "/combinations";
    AppRoute["CHANNELS"] = "/simulation";
    AppRoute["SIMULATION"] = "/simulation/combinations";
    AppRoute["IFRAME_PLACEHOLDER"] = "/iframe-placeholder";
})(AppRoute || (AppRoute = {}));
