/* eslint-disable max-len */
import React from 'react';
var TemplateNoLayouts = function () {
    return (React.createElement("svg", { width: "134", height: "106", viewBox: "0 0 134 106", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { width: "134", height: "105", transform: "translate(0 1)", fill: "white", fillOpacity: "0.01" }),
        React.createElement("path", { d: "M67 106C96.8234 106 121 100.851 121 94.5C121 88.1487 96.8234 83 67 83C37.1766 83 13 88.1487 13 94.5C13 100.851 37.1766 106 67 106Z", fill: "#F5F5F5" }),
        React.createElement("path", { d: "M33.0035 0.5H33.5035H100.996C101.928 0.5 102.82 0.864864 103.476 1.5121C104.133 2.15909 104.5 3.03508 104.5 3.94695V96.053C104.5 96.9649 104.133 97.8409 103.476 98.4879C102.82 99.1351 101.928 99.5 100.996 99.5H33.0035C32.0721 99.5 31.1801 99.1351 30.5236 98.4879C29.8674 97.8409 29.5 96.9649 29.5 96.053V3.94695C29.5 3.03508 29.8674 2.15909 30.5236 1.5121C31.1801 0.864864 32.0721 0.5 33.0035 0.5Z", fill: "#F8F9FA", stroke: "#CED4DA" }),
        React.createElement("rect", { x: "34.5", y: "5.5", width: "65", height: "25.6667", rx: "1.5", fill: "white", stroke: "#E9ECEF" }),
        React.createElement("rect", { x: "34.5", y: "37.1666", width: "65", height: "25.6667", rx: "1.5", fill: "white", stroke: "#E9ECEF" }),
        React.createElement("rect", { x: "34.5", y: "68.8334", width: "65", height: "25.6667", rx: "1.5", fill: "white", stroke: "#E9ECEF" })));
};
export default TemplateNoLayouts;
