var _this = this;
import classNames from 'classnames';
import React, { useImperativeHandle, useRef } from 'react';
import LayoutPreview from 'components/LayoutPreview';
import AssetIdField from '../Section/AssetIdField';
import styles from './styles.module.scss';
var LayoutPreviewList = React.forwardRef(function (_a, ref) {
    var layouts = _a.layouts, onItemClick = _a.onItemClick;
    var items = useRef({});
    useImperativeHandle(ref, function () { return items.current; }, []);
    return (React.createElement("div", { className: styles.layoutWrapper }, layouts.map(function (layout) {
        var _a;
        return (React.createElement("div", { onClick: onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick.bind(_this, layout), className: classNames(styles.layoutContainer, 'd-flex'), key: layout.id, ref: function (item) {
                items.current[layout.id] = item;
            } },
            React.createElement("div", { className: classNames('position-sticky', styles.sectionStickyPanel) },
                React.createElement("div", { className: 'border border-1 rounded ps-2 mt-1 shadow bg-white w-max-content' },
                    React.createElement(AssetIdField, { layout: layout }))),
            React.createElement(LayoutPreview, { stringifiedClassName: classNames(styles.layout, (_a = {}, _a[styles.active] = layout.active, _a)), documentId: layout.documentId })));
    })));
});
LayoutPreviewList.displayName = 'LayoutPreviewList';
export default LayoutPreviewList;
