var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
import { addBundleGroup, getPersistentBundlesGroups, replaceBundleGroups } from './helpers';
export var modulesInitialState = {
    status: SAGA_STATUS.IDLE,
    error: '',
    groups: {},
};
var getGroupsKey = function (info) {
    return "jobzero=".concat(info.jobZero.id, "&bundle=").concat(info.moduleBundle.id);
};
var mutateGroups = function (state, bundles) {
    var info = state.info;
    if (!info) {
        return;
    }
    var key = getGroupsKey(info);
    state.groups[key] = state.groups[key] || {};
    state.groups[key] = getPersistentBundlesGroups(bundles);
};
export var modulesSlice = createSlice({
    name: 'modules',
    initialState: modulesInitialState,
    reducers: {
        getModulesStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
            state.sections = undefined;
            state.bundles = undefined;
            state.info = undefined;
        },
        getModulesSuccess: function (state, action) {
            var _a = action.payload, jobZero = _a.jobZero, moduleBundle = _a.moduleBundle, bundles = _a.bundles, sections = _a.sections;
            state.info = { jobZero: jobZero, moduleBundle: moduleBundle };
            state.sections = sections;
            var bundlesForState = bundles.map(function (bundle, bundleIndex) { return (__assign(__assign({}, bundle), { index: bundleIndex, modules: bundle.modules.map(function (module, moduleIndex) { return (__assign(__assign({}, module), { selected: bundle.modules.length === 1, disabled: false, index: moduleIndex })); }), groups: [] })); });
            bundlesForState = (state.groups[getGroupsKey(state.info)] || []).reduce(function (acc, bundleGroups) {
                var bundleIndex = bundleGroups.bundleIndex, groups = bundleGroups.groups;
                return groups.reduce(function (innerAcc, moduleIndexes) { return addBundleGroup(innerAcc, bundleIndex, moduleIndexes); }, acc);
            }, bundlesForState);
            state.bundles = bundlesForState;
            state.status = SAGA_STATUS.IDLE;
            mutateGroups(state, bundlesForState);
        },
        getModulesError: function (state, action) {
            var _a;
            state.status = SAGA_STATUS.IDLE;
            state.error = (_a = action.payload) !== null && _a !== void 0 ? _a : 'Unknown modules error';
        },
        updateModulesBundles: function (state, action) {
            state.bundles = __spreadArray([], action.payload, true);
        },
        createGroup: function (state, action) {
            var _a = action.payload, bundleIndex = _a.bundleIndex, moduleIndexes = _a.moduleIndexes;
            var _b = state.bundles, bundles = _b === void 0 ? [] : _b;
            var updatedBundles = addBundleGroup(bundles, bundleIndex, moduleIndexes);
            state.bundles = updatedBundles;
            mutateGroups(state, updatedBundles);
        },
        removeGroup: function (state, action) {
            var _a, _b;
            var _c = action.payload, bundleIndex = _c.bundleIndex, groupIndex = _c.groupIndex;
            var _d = state.bundles, bundles = _d === void 0 ? [] : _d;
            var updatedGroups = ((_b = (_a = bundles[bundleIndex]) === null || _a === void 0 ? void 0 : _a.groups) !== null && _b !== void 0 ? _b : []).map(function (i) { return i; }); // for sparse array
            delete updatedGroups[groupIndex];
            var updatedBundles = replaceBundleGroups(bundles, bundleIndex, updatedGroups);
            state.bundles = updatedBundles;
            mutateGroups(state, updatedBundles);
        },
        shiftModuleInGroup: function (state, action) {
            var _a;
            var _b = action.payload, bundleIndex = _b.bundleIndex, groupIndex = _b.groupIndex, moduleIndex = _b.moduleIndex, shift = _b.shift;
            var _c = state.bundles, bundles = _c === void 0 ? [] : _c;
            var bundle = bundles[bundleIndex];
            var group = bundle === null || bundle === void 0 ? void 0 : bundle.groups[groupIndex];
            var srcIdx = group === null || group === void 0 ? void 0 : group.modules.findIndex(function (module) { return module.index === moduleIndex; });
            if (!group || srcIdx === undefined || srcIdx < 0) {
                return;
            }
            var swapIdx = Math.min(Math.max(srcIdx + shift, 0), group.modules.length - 1);
            var updatedModules = __spreadArray([], group.modules, true);
            _a = [updatedModules[swapIdx], updatedModules[srcIdx]], updatedModules[srcIdx] = _a[0], updatedModules[swapIdx] = _a[1];
            var updatedGroups = bundles[bundleIndex].groups.map(function (i) { return i; }); // for sparse array
            updatedGroups[groupIndex] = __assign(__assign({}, group), { modules: updatedModules });
            var updatedBundles = replaceBundleGroups(bundles, bundleIndex, updatedGroups);
            state.bundles = updatedBundles;
            mutateGroups(state, updatedBundles);
        },
        resetModules: function (state) { return (__assign(__assign({}, modulesInitialState), { groups: state.groups })); },
    },
});
export default modulesSlice.reducer;
// ACTIONS
export var actions = __assign(__assign({}, modulesSlice.actions), { getModulesRequest: createAction('getModulesRequest') });
// SELECTORS
export var selectModulesLoadingStatus = function (state) {
    return state.modules.status === SAGA_STATUS.PENDING;
};
export var selectModulesInfo = function (state) { return state.modules.info; };
export var selectModulesSections = function (state) { return state.modules.sections; };
export var selectModulesBundles = function (state) {
    var bundles = state.modules.bundles;
    return bundles && bundles.length ? bundles : undefined;
};
export var selectModulesError = function (state) { return state.modules.error; };
