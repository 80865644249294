/* eslint-disable multiline-ternary */
import React from 'react';
import ExclamationBadge from 'assets/images/ExclamationBadge';
import styles from './Tab.module.scss';
var Tab = function (_a) {
    var count = _a.count, index = _a.index, isActive = _a.isActive, _b = _a.isShowWarning, isShowWarning = _b === void 0 ? false : _b, label = _a.label, _c = _a.testId, testId = _c === void 0 ? '' : _c, onClick = _a.onClick;
    return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    React.createElement("li", { className: "nav-item", "data-testid": testId },
        React.createElement("button", { className: "d-flex justify-content-between align-items-center px-3 py-2 mb-2 ".concat(isActive ? styles.active : '', " ").concat(styles.button), type: "button", style: { width: '100%' }, onClick: onClick, "data-index": index },
            label,
            count ? (React.createElement("span", { className: "badge rounded-5 text-bg-light" }, count)) : null,
            isShowWarning ? (React.createElement("span", { className: "mx-1" },
                React.createElement(ExclamationBadge, null))) : null)));
};
export default Tab;
