// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_layoutWrapper__r3uXD {
  display: flex;
  flex-direction: column;
  padding: 6px 20px 26px 16px;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.styles_layoutContainer__2Bskp {
  padding: 10px 0 10px 0;
}
.styles_layoutContainer__2Bskp:hover .styles_sectionStickyPanel__pcboV {
  display: block;
}

.styles_layout__-AJKK {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #dee2e6;
}
.styles_layout__-AJKK:hover {
  border-color: #6C757D;
}
.styles_layout__-AJKK.styles_active__aUUHC {
  outline: 4px solid #00857C;
}

.styles_sectionStickyPanel__pcboV {
  width: 0;
  height: 2.5rem;
  top: 1px;
  left: 20px;
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/LayoutPreviewList/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gCAAA;AAOF;;AAJA;EACE,sBAAA;AAOF;AAJI;EACE,cAAA;AAMN;;AADA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;AAIF;AAFE;EACE,qBAAA;AAIJ;AADE;EACE,0BAAA;AAGJ;;AACA;EACE,QAAA;EACA,cAAA;EACA,QAAA;EACA,UAAA;EACA,aAAA;AAEF","sourcesContent":["@use '../../styles/_variables.module';\n\n.layoutWrapper {\n  display: flex;\n  flex-direction: column;\n  padding: 6px 20px 26px 16px;\n  align-items: center;\n  border-bottom: 1px solid variables.$borderColor;\n}\n\n.layoutContainer {\n  padding: 10px 0 10px 0;\n\n  &:hover {\n    .sectionStickyPanel {\n      display: block;\n    }\n  }\n}\n\n.layout {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  cursor: pointer;\n  border-radius: 2px;\n  border: 1px solid variables.$borderColor;\n\n  &:hover {\n    border-color: #6C757D;\n  }\n\n  &.active {\n    outline: 4px solid #00857C;\n  }\n}\n\n.sectionStickyPanel {\n  width: 0;\n  height: 2.5rem;\n  top: 1px;\n  left: 20px;\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearSectionButtonColor": `#6C757D`,
	"clearAllButtonPrimary": `#F8F9FA`,
	"clearAllButtonDisabled": `#A1BFBE`,
	"buttonBorderRadius": `3.2px`,
	"buttonFontSize": `14px`,
	"layoutWrapper": `styles_layoutWrapper__r3uXD`,
	"layoutContainer": `styles_layoutContainer__2Bskp`,
	"sectionStickyPanel": `styles_sectionStickyPanel__pcboV`,
	"layout": `styles_layout__-AJKK`,
	"active": `styles_active__aUUHC`
};
export default ___CSS_LOADER_EXPORT___;
