// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_contentContainer__nCQbG {
  width: auto;
  padding-top: 32px;
  color: #6C757D;
  text-overflow: ellipsis;
  font-family: Helvetica Neue, Roboto, Arial, sans-serif;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
}

.styles_TemplateContainer__Z9n\\+N {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  padding-top: 24px;
}

.styles_IframePlaceholder__nQdGs {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;
  color: #808080;
}
.styles_IframePlaceholder__nQdGs .styles_text__TtgCj {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  font-size: 20px;
}
.styles_IframePlaceholder__nQdGs .styles_hint__lkznv {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Channels/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,uBAAA;EACA,sDAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,yCAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".contentContainer {\n  width: auto;\n  padding-top: 32px;\n  color: #6C757D;\n  text-overflow: ellipsis;\n  font-family: Helvetica Neue, Roboto, Arial, sans-serif;\n  overflow: hidden;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 150%;\n}\n\n.TemplateContainer {\n  display: flex;\n  flex-wrap: wrap;\n  width: auto;\n  padding-top: 24px;\n}\n\n.IframePlaceholder {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n  margin-top: 30px;\n  font-family: Arial, Helvetica, sans-serif;\n  color: #808080;\n\n  .text {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 5px;\n    font-size: 20px;\n  }\n\n  .hint {\n    font-size: 14px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `styles_contentContainer__nCQbG`,
	"TemplateContainer": `styles_TemplateContainer__Z9n+N`,
	"IframePlaceholder": `styles_IframePlaceholder__nQdGs`,
	"text": `styles_text__TtgCj`,
	"hint": `styles_hint__lkznv`
};
export default ___CSS_LOADER_EXPORT___;
