import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
var Modal = function (props) {
    var children = props.children, isOpen = props.isOpen, onClose = props.onClose, size = props.size, title = props.title;
    if (!isOpen) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        ReactDOM.createPortal(React.createElement("div", { className: "modal-backdrop fade show" }), document.getElementById('backdrop-root')),
        ReactDOM.createPortal(React.createElement("div", { className: "modal ".concat(styles.modal), onClick: onClose, role: "presentation" },
            React.createElement("div", { className: "modal-dialog modal-dialog-scrollable modal-dialog-centered modal-".concat(size || ''), onClick: function (event) { return event.stopPropagation(); }, role: "presentation" },
                React.createElement("div", { className: "modal-content" },
                    title && (React.createElement("div", { className: "modal-header" },
                        React.createElement("h5", { className: "modal-title" }, title),
                        React.createElement("button", { onClick: onClose, type: "button", className: "btn-close", "aria-label": "Close" }))),
                    children))), document.getElementById('modal-root'))));
};
export default Modal;
