import { inflect } from 'inflection';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { buildCombinations } from './utils';
export function isCombinationItemWithGroup(item) {
    return item.groupIdx !== undefined;
}
var toastDanger = function (msg) {
    toast(msg, {
        hideProgressBar: true,
        style: { backgroundColor: '#da3737' },
    });
};
var toastForCombinationsNumberChange = function (delta) {
    if (delta === 0) {
        return;
    }
    if (delta > 0) {
        toast("".concat(delta, " ").concat(inflect('combination', delta), " ").concat(inflect('was', delta, 'was', 'were'), " added"), { hideProgressBar: true });
    }
    else {
        var count = Math.abs(delta);
        toastDanger("".concat(count, " ").concat(inflect('combination', count), " ").concat(inflect('was', count, 'was', 'were'), " removed"));
    }
};
var countCombinations = function (combinations) { return combinations.filter(function (i) { return i; }).length; };
export function useCombinations(sections, bundles) {
    var _a = useState([]), combinations = _a[0], setCombinations = _a[1];
    var combinationsRef = useRef(combinations); // to show toast
    var combinationsNumber = useMemo(function () { return countCombinations(combinations); }, [combinations]);
    var removeCombinations = useCallback(function (indexes, showToast) {
        if (showToast === void 0) { showToast = true; }
        setCombinations(function (sparseArray) {
            var newList = sparseArray.map(function (item) { return item; });
            indexes.forEach(function (idx) {
                delete newList[idx]; // nosonar
            });
            combinationsRef.current = newList; // to skip toast
            return newList;
        });
        if (showToast) {
            if (indexes.length > 1) {
                toastForCombinationsNumberChange(-indexes.length);
            }
            else {
                toastDanger("Combination ".concat(indexes[0] + 1, " was removed"));
            }
        }
    }, []);
    var combinationsPossibleNumber = useMemo(function () { return bundles && sections ? buildCombinations(bundles, sections, false).length : 0; }, [bundles, sections]);
    useEffect(function () {
        if (!bundles || !sections) {
            return;
        }
        setCombinations(buildCombinations(bundles, sections));
    }, [bundles, sections]);
    useEffect(function () {
        if (combinationsRef.current === combinations) {
            return;
        }
        var delta = combinationsNumber - countCombinations(combinationsRef.current);
        toastForCombinationsNumberChange(delta);
        combinationsRef.current = combinations;
    }, [combinations]); // combinationsNumber intentionally skiped from dependencies
    return {
        combinations: combinations,
        combinationsNumber: combinationsNumber,
        combinationsPossibleNumber: combinationsPossibleNumber,
        removeCombinations: removeCombinations,
    };
}
