import React, { useEffect, useRef } from 'react';
import styles from '../Modules.module.scss';
var BundleListTitle = function (props) {
    var id = props.id, title = props.title, selectedNumber = props.selectedNumber, totalNumber = props.totalNumber, onChange = props.onChange, onCreateGroup = props.onCreateGroup;
    var htmlId = "variant-".concat(id);
    var ref = useRef(null);
    useEffect(function () {
        if (!ref.current) {
            return;
        }
        ref.current.indeterminate = totalNumber > 0 && selectedNumber > 0 && selectedNumber < totalNumber;
    }, [selectedNumber, totalNumber]);
    return (React.createElement("div", { className: "d-flex justify-content-between align-items-center mb-3 p-2 rounded-2 ".concat(styles.modulesListTitle) },
        React.createElement("div", null,
            React.createElement("input", { className: "form-check-input custom-check me-2", type: "checkbox", value: "", id: htmlId, ref: ref, onChange: onChange, checked: totalNumber === selectedNumber, disabled: totalNumber === 0 }),
            React.createElement("label", { className: "form-check-label fw-bold", htmlFor: htmlId }, "".concat(selectedNumber, "/").concat(totalNumber, " ").concat(title))),
        onCreateGroup && (React.createElement("button", { className: "me-3 btn btn-outline-primary custom-button", onClick: onCreateGroup, type: "button" }, "Create Module Group"))));
};
export default BundleListTitle;
