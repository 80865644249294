import React from 'react';
import CopyClipboardIcon from './CopyClipboardIcon';
var AssetIdField = function (_a) {
    var layout = _a.layout;
    var assetNumber = layout === null || layout === void 0 ? void 0 : layout.number;
    return (React.createElement("div", { className: "d-flex align-items-center justify-content-between text-nowrap fw-light", onClick: function (event) { return event.stopPropagation(); } },
        React.createElement("small", { className: 'me-2' }, assetNumber),
        React.createElement(CopyClipboardIcon, { text: assetNumber })));
};
export default AssetIdField;
