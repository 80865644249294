// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavBar_navBar__4kTTi {
  background-color: #00544E;
  height: 50px;
  position: fixed;
  width: 100%;
}
.NavBar_navBar__4kTTi.discardPositionFixed {
  position: unset;
  height: auto;
}
.NavBar_navBar__4kTTi .NavBar_heading__0O9qx {
  font-size: 20px;
  color: white;
}
.NavBar_navBar__4kTTi .NavBar_textContent__ivyxX {
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  color: white;
  padding: 0 4px;
}
.NavBar_navBar__4kTTi .NavBar_moduleCombinationLabel__xGHcC {
  font-size: 12px;
  color: white;
  margin: 0 0 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.module.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;AAMF;AAJE;EACE,eAAA;EACA,YAAA;AAMJ;AAHE;EACE,eAAA;EACA,YAAA;AAKJ;AAFE;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AAIJ;AADE;EACE,eAAA;EACA,YAAA;EACA,kBAAA;AAGJ","sourcesContent":["@use '../../styles/_variables.module';\r\n\r\n\r\n.navBar {\r\n  background-color: #00544E;\r\n  height: #{variables.$navbarHeight};\r\n  position: fixed;\r\n  width: 100%;\r\n\r\n  &:global(.discardPositionFixed) {\r\n    position: unset;\r\n    height: auto;\r\n  }\r\n\r\n  .heading {\r\n    font-size: 20px;\r\n    color: white;\r\n  }\r\n\r\n  .textContent {\r\n    border: 1px solid #f8f9fa;\r\n    border-radius: 5px;\r\n    color: white;\r\n    padding: 0 4px;\r\n  }\r\n\r\n  .moduleCombinationLabel {\r\n    font-size: 12px;\r\n    color: white;\r\n    margin: 0 0 0 10px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearSectionButtonColor": `#6C757D`,
	"clearAllButtonPrimary": `#F8F9FA`,
	"clearAllButtonDisabled": `#A1BFBE`,
	"buttonBorderRadius": `3.2px`,
	"buttonFontSize": `14px`,
	"navBar": `NavBar_navBar__4kTTi`,
	"heading": `NavBar_heading__0O9qx`,
	"textContent": `NavBar_textContent__ivyxX`,
	"moduleCombinationLabel": `NavBar_moduleCombinationLabel__xGHcC`
};
export default ___CSS_LOADER_EXPORT___;
