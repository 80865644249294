import { useState } from 'react';
import { getResourceTypeFilterDataRequest, selectSelectedFilters, setSelectedFilters, } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getJobZeroRequest, reset } from 'store/jobZeroSlice';
import { getSessionId } from 'utils';
export var useFilters = function (onJobZeroRequestSubmitted) {
    var dispatch = useAppDispatch();
    var sessionId = getSessionId();
    var selectedFilters = useAppSelector(selectSelectedFilters);
    var _a = useState(selectedFilters.selectedCountry), country = _a[0], setCountry = _a[1];
    var _b = useState(selectedFilters.selectedProductFamily), productFamily = _b[0], setProductFamily = _b[1];
    var _c = useState(selectedFilters.selectedResourceType), resourceType = _c[0], setResourceType = _c[1];
    var _d = useState(selectedFilters.selectedDeliveryMethod), deliveryMethod = _d[0], setDeliveryMethod = _d[1];
    var _e = useState(selectedFilters.selectedDeploymentSupplier), deploymentSupplier = _e[0], setDeploymentSupplier = _e[1];
    var onCountryChangeHandler = function (value) {
        setCountry(value);
        dispatch(getResourceTypeFilterDataRequest(value));
    };
    var onProductFamilyChangeHandler = function (value) {
        setProductFamily(value);
    };
    var onResourceTypeChangeHandler = function (value) {
        setResourceType(value);
    };
    var onDeliveryMethodChangeHandler = function (value) {
        setDeliveryMethod(value);
    };
    var onDeploymentSupplierChangeHandler = function (value) {
        setDeploymentSupplier(value);
    };
    var onFormSubmitHandler = function (event) {
        event.preventDefault();
        dispatch(reset());
        dispatch(setSelectedFilters({
            selectedCountry: country,
            selectedProductFamily: productFamily,
            selectedResourceType: resourceType,
            selectedDeliveryMethod: deliveryMethod,
            selectedDeploymentSupplier: deploymentSupplier,
        }));
        dispatch(getJobZeroRequest({
            sessionId: sessionId,
            productFamily: productFamily,
            country: country,
            resourceType: resourceType,
            deliveryMethod: deliveryMethod || '',
            deploymentSupplier: deploymentSupplier || '',
            onlyFirstPage: true,
        }));
        onJobZeroRequestSubmitted(true);
    };
    return {
        country: country,
        productFamily: productFamily,
        resourceType: resourceType,
        deliveryMethod: deliveryMethod,
        deploymentSupplier: deploymentSupplier,
        isButtonDisabled: !country || !productFamily || !resourceType,
        onCountryChangeHandler: onCountryChangeHandler,
        onProductFamilyChangeHandler: onProductFamilyChangeHandler,
        onResourceTypeChangeHandler: onResourceTypeChangeHandler,
        onDeliveryMethodChangeHandler: onDeliveryMethodChangeHandler,
        onDeploymentSupplierChangeHandler: onDeploymentSupplierChangeHandler,
        onFormSubmitHandler: onFormSubmitHandler,
    };
};
