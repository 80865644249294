var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { filterModuleBundleLayouts } from './filterModuleBundleLayouts';
export var mergeSimulationCombinations = function (bundle, templateSections) {
    var formattedScreens = filterModuleBundleLayouts(bundle === null || bundle === void 0 ? void 0 : bundle.screens);
    return formattedScreens.reduce(function (acc, screen) {
        var sections = templateSections.filter(function (templateSection) {
            var splitedScreenTypeByWords = screen.displayName.toLocaleLowerCase().split(' ');
            var splitedSectionTypeByWords = templateSection.displayName.toLocaleLowerCase().split(' ');
            return splitedScreenTypeByWords.every(function (word) { return splitedSectionTypeByWords.includes(word); });
        });
        if (sections.length && screen.layouts.length) {
            var sectionsIds = sections.map(function (section) { return section.id; });
            var screenWithCorrespondingSectionId = __assign(__assign({}, screen), { sectionIds: sectionsIds });
            acc.push(screenWithCorrespondingSectionId);
        }
        return acc;
    }, []);
};
