import React, { useMemo, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import Tab from 'components/Tab';
import { buildCombinations } from 'pages/Combinations/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectModulesLoadingStatus, selectModulesError, selectModulesInfo, selectModulesSections, actions, } from 'store/modules/slice';
import BundleList from './components/BundleList';
import BundlesNavigation from './components/BundlesNavigation';
import { BundlesContext } from './context/bundles';
import { convertBundleWithSelectionToBundle, validateSelection } from './hooks/helpers';
import useBundles from './hooks/useBundles';
import styles from './Modules.module.scss';
export var moduleTypeTestId = 'moduleTypeTestId';
var getParams = function (modal, queryParams) {
    var _a, _b;
    if (modal) {
        return [modal.jobZeroId, modal.moduleBundleId];
    }
    else {
        return [(_a = queryParams.get('jobzero')) !== null && _a !== void 0 ? _a : '', (_b = queryParams.get('bundle')) !== null && _b !== void 0 ? _b : ''];
    }
};
var validateParams = function (jobZeroId, moduleBundleId) { return !!(jobZeroId && moduleBundleId); };
var Modules = function (_a) {
    var modal = _a.modal;
    var navigate = useNavigate();
    var queryParams = useSearchParams()[0];
    var _b = getParams(modal, queryParams), jobZeroId = _b[0], moduleBundleId = _b[1];
    var dispatchToRedux = useAppDispatch();
    var dataInfo = useAppSelector(selectModulesInfo);
    var dataSections = useAppSelector(selectModulesSections);
    var dataLoading = useAppSelector(selectModulesLoadingStatus);
    var dataError = useAppSelector(selectModulesError);
    var _c = useState(false), isSubmitAttempt = _c[0], setIsSubmitAttempt = _c[1];
    var _d = useBundles(), bundles = _d.bundles, bundlesIsActive = _d.bundlesIsActive, bundlesDispatch = _d.bundlesDispatch, selectedCount = _d.selectedCount, selectionIsValid = _d.selectionIsValid, activeBundleIndex = _d.activeBundleIndex, setActiveBundleIndex = _d.setActiveBundleIndex, setNextBundleActive = _d.setNextBundleActive, setPrevBundleActive = _d.setPrevBundleActive;
    var bundlesContext = useMemo(function () { return ({
        bundles: bundles,
        bundlesDispatch: bundlesDispatch,
    }); }, [bundles, bundlesDispatch]);
    useEffect(function () {
        var sameParams = (dataInfo === null || dataInfo === void 0 ? void 0 : dataInfo.jobZero.id) === jobZeroId && (dataInfo === null || dataInfo === void 0 ? void 0 : dataInfo.moduleBundle.id) === moduleBundleId;
        if (!sameParams && validateParams(jobZeroId, moduleBundleId)) {
            dispatchToRedux(actions.getModulesRequest({
                jobZeroId: jobZeroId,
                moduleBundleId: moduleBundleId,
            }));
        }
    }, [jobZeroId, moduleBundleId]); // dataInfo intentionally skipped from the deps
    var resultedCombinationsNumber = useMemo(function () { return (dataSections && bundles.length) ? buildCombinations(bundles, dataSections).length : 0; }, [bundles, dataSections]);
    var onBrandClick = function () {
        dispatchToRedux(actions.resetModules());
    };
    var onFormSubmitHandler = function (evt) {
        evt.preventDefault();
        setIsSubmitAttempt(true);
        if (!validateSelection(bundles)) {
            return;
        }
        dispatchToRedux(actions.updateModulesBundles(bundles.map(convertBundleWithSelectionToBundle)));
        if (modal) {
            // in case this button is clicked from the modal dialog of Preview page
            modal.onFormSubmit();
        }
        else {
            navigate('/combinations');
        }
    };
    var activeBundle = bundles[activeBundleIndex];
    if (!validateParams(jobZeroId, moduleBundleId) || dataError) {
        if (modal) {
            return (React.createElement("div", { className: 'text-danger' }, "Invalid parameters ".concat(jobZeroId, "/").concat(moduleBundleId)));
        }
        else {
            return React.createElement(Navigate, { to: "/" });
        }
    }
    if (dataLoading || !dataInfo || !bundlesIsActive || !dataSections || !activeBundle) {
        return (React.createElement("div", { className: "container d-flex align-items-center justify-content-center", style: { height: '100vh' } },
            React.createElement(LoadingSpinner, null)));
    }
    var jobZero = dataInfo.jobZero;
    if (modal === null || modal === void 0 ? void 0 : modal.isNoMoreToAdd) {
        return (React.createElement(React.Fragment, null,
            React.createElement(NavBar, { textContent: jobZero.name }),
            React.createElement("main", { className: "container-fluid mx-lg-5 px-3 ".concat(styles.contentContainer) },
                React.createElement("h4", { className: "p-0 m-0 mb-1", style: { width: 'auto' } }, "Select the modules you want to use"),
                React.createElement("p", { className: "p-0 m-0 pb-3 border-bottom" }, "Select at least one module."),
                React.createElement("div", { className: "container d-flex align-items-center justify-content-center", style: { flex: '1 1 0', fontWeight: 500 } }, "All possible combinations have been added"))));
    }
    var activeBundleLacksPlaceholder = dataSections.findIndex(function (i) { return i.placeholder && i.bundleIndexes.includes(activeBundleIndex); }) < 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(NavBar, { textContent: jobZero.name, onBrandClick: onBrandClick }),
        React.createElement("main", { className: "container-fluid mx-lg-5 px-3 ".concat(styles.contentContainer) },
            React.createElement("h4", { className: "p-0 m-0 mb-1", style: { width: 'auto' } }, "Select the modules you want to use"),
            React.createElement("p", { className: "p-0 m-0 pb-3 border-bottom" }, "Select at least one module."),
            React.createElement("form", { className: "d-flex pt-4", onSubmit: onFormSubmitHandler },
                React.createElement("div", { className: "me-5 flex-shrink-0 ".concat(styles.tabsContainer) },
                    React.createElement("h6", { className: "mb-3 fw-bold" }, "Module Types"),
                    React.createElement("ul", { className: "nav nav-pills flex-column flex-nowrap mb-4 ".concat(styles.tabs) }, bundles.map(function (bundle, index) {
                        return (React.createElement(Tab, { key: bundle.index, count: bundle.selectedCount, index: index, isActive: index === activeBundleIndex, label: bundle.type, testId: moduleTypeTestId, onClick: function () { return setActiveBundleIndex(index); } }));
                    })),
                    isSubmitAttempt && !selectionIsValid && (React.createElement("div", { className: styles.selectAtLeastOneModule }, "Select at least one module to create combinations.")),
                    React.createElement(Button, { type: "submit", isDisabled: false, label: selectionIsValid ? "Add Combinations (".concat(resultedCombinationsNumber, ")") : 'Add Combinations', styleOverride: { width: '100%' }, className: "mb-3" }),
                    selectionIsValid && (React.createElement("div", { className: styles.possibleCombinationsMessage }, "".concat(resultedCombinationsNumber, " combination(s) will be added. You will have the option to review\n                the content in the next step."))),
                    !selectionIsValid && selectedCount > 0 && (React.createElement("div", { className: styles.selectRemainingModulesMessage }, "Select modules for the remaining module types to create combinations."))),
                React.createElement("div", { className: "flex-grow-1 ".concat(styles.modulesContainer) },
                    activeBundleLacksPlaceholder && (React.createElement("div", { className: 'text-danger pt-2 pb-4 px-2' }, "no placeholder in the template")),
                    React.createElement(BundlesContext.Provider, { value: bundlesContext },
                        React.createElement(BundleList, { bundleIndex: activeBundleIndex })),
                    React.createElement(BundlesNavigation, { onPrev: setPrevBundleActive, onNext: setNextBundleActive }))))));
};
export default Modules;
