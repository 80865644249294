var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isCombinationItemWithGroup } from './useCombinations';
export function validateSectionForCombination(section) {
    return !!(section.placeholder && section.bundleIndexes.length);
}
export function combinationCreateKey(combination) {
    return combination
        .map(function (item) {
        if (!item) {
            return 'x';
        }
        if (isCombinationItemWithGroup(item)) {
            return "".concat(item.bundleIdx, "g").concat(item.groupIdx);
        }
        return "".concat(item.bundleIdx).concat(item.moduleIdx);
    })
        .join('-');
}
export function combinationGetModuleForSection(bundles, combination, idx) {
    var item = combination[idx];
    if (!item) {
        return null;
    }
    var bundleIdx = item.bundleIdx;
    var bundle = bundles[bundleIdx];
    if (!bundle) {
        return null;
    }
    var modules = isCombinationItemWithGroup(item) ? item.modules : [item.moduleIdx];
    var validModules = modules
        .map(function (moduleIdx) { return bundle.modules[moduleIdx]; })
        .filter(function (module) { return Boolean(module); });
    if (!validModules.length) {
        return null;
    }
    return [validModules, bundle];
}
var bundleIndexesToString = function (indexes) { return __spreadArray([], indexes, true).sort(function (a, b) { return a - b; }).toString(); };
var populateCombination = function (combination, sectionIndexes, layout) {
    var clone = combination.map(function (i) { return i; });
    sectionIndexes.forEach(function (idx) {
        clone[idx] = __assign({}, layout);
    });
    return clone;
};
export function buildCombinations(bundles, sections, selectedOnly) {
    if (selectedOnly === void 0) { selectedOnly = true; }
    var groupedBundleIndexes = new Map();
    var sectionsToCompute = sections.flatMap(function (section, index) {
        if (!validateSectionForCombination(section)) {
            return [];
        }
        var bundlesKey = bundleIndexesToString(section.bundleIndexes);
        groupedBundleIndexes.set(bundlesKey, section.bundleIndexes);
        return [{ index: index, bundleIndexes: bundlesKey }];
    });
    if (!groupedBundleIndexes.size) {
        return [];
    }
    var combinations = [new Array(sections.length)];
    groupedBundleIndexes.forEach(function (values, key) {
        var sectionIndexes = sectionsToCompute.filter(function (item) { return item.bundleIndexes === key; }).map(function (item) { return item.index; });
        var layouts = values
            .flatMap(function (bundleIdx) {
            var _a = bundles[bundleIdx], modules = _a.modules, groups = _a.groups;
            return __spreadArray(__spreadArray([], modules.filter(function (module) { return !module.disabled && (!selectedOnly || module.selected); })
                .map(function (module) { return ({
                bundleIdx: bundleIdx,
                moduleIdx: module.index,
            }); }), true), groups.filter(function (group) { return !selectedOnly || group.selected; })
                .map(function (group) { return ({
                bundleIdx: bundleIdx,
                groupIdx: group.index,
                modules: group.modules.map(function (module) { return module.index; }),
            }); }), true);
        })
            .map(function (module, index) { return (__assign(__assign({}, module), { index: index })); });
        if (layouts.length) {
            combinations = combinations.flatMap(function (combination) { return layouts.map(function (layout) {
                return populateCombination(combination, sectionIndexes, layout);
            }); });
        }
    });
    return combinations;
}
var RECIPE_EMPTY_MODULE = 'empty';
export function buildRecipeCombinations(combinations, bundles, sections) {
    var list = Array.from(new Array(combinations.length), function () { return []; });
    sections.forEach(function (section, sectionIdx) {
        if (!section.placeholder) {
            return;
        }
        var zone = section.placeholder.name;
        var zoneScreenName = section.surface.name;
        var maxNumberOfModules = 1;
        var modules = combinations.map(function (combination) {
            if (!section.bundleIndexes.length) {
                return [];
            }
            var data = combinationGetModuleForSection(bundles, combination, sectionIdx);
            if (!data) {
                return [];
            }
            var sectionModules = data[0], sectionBundle = data[1];
            maxNumberOfModules = maxNumberOfModules > sectionModules.length ? maxNumberOfModules : sectionModules.length;
            return sectionModules.map(function (module) { return ({
                zone: zone,
                zoneScreenName: zoneScreenName,
                moduleDocNumber: module.number,
                moduleScreenName: sectionBundle.name,
            }); });
        });
        modules.forEach(function (modulesOfCombination, idx) {
            var _a;
            var emptyItems = Array.from(new Array(maxNumberOfModules - modulesOfCombination.length), function () { return ({
                zone: zone,
                zoneScreenName: zoneScreenName,
                moduleDocNumber: RECIPE_EMPTY_MODULE,
            }); });
            (_a = list[idx]).push.apply(_a, __spreadArray(__spreadArray([], modulesOfCombination, false), emptyItems, false));
        });
    });
    return list;
}
