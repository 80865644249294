import classNames from 'classnames';
import React from 'react';
import { CLEAR_ZONE_BUTTON } from 'const';
import styles from './styles.module.scss';
var CancelPanel = function (_a) {
    var className = _a.className, onClick = _a.onClick;
    return (React.createElement("div", { className: classNames(styles.cancelPanel, className), "data-testid": "simulation-section-cancel-panel" },
        React.createElement("button", { type: 'button', className: "btn btn-sm btn-light shadow", onClick: onClick }, CLEAR_ZONE_BUTTON)));
};
export default CancelPanel;
