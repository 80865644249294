var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable jest/require-hook */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer from './authSlice';
import channelsReducer from './channelsSlice';
import filtersReducer from './filtersSlice';
import jobZeroReducer from './jobZeroSlice';
import modulesReducer, { modulesInitialState } from './modules/slice';
import { saveToLocalStorage, loadFromLocalStorage } from './persistentState';
import recipeReducer from './recipeSlice';
import rootSaga from './sagas';
import simulationReducer from './simulationSlice';
import userReducer from './userSlice';
var sagaMiddleware = createSagaMiddleware();
var store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        filters: filtersReducer,
        jobZero: jobZeroReducer,
        modules: modulesReducer,
        recipe: recipeReducer,
        channels: channelsReducer,
        simulation: simulationReducer,
    },
    preloadedState: (function () {
        var _a = loadFromLocalStorage() || {}, simulation = _a.simulation, modulesGroups = _a.modulesGroups;
        return {
            simulation: simulation,
            modules: __assign(__assign({}, modulesInitialState), { groups: __assign({}, modulesGroups) }),
        };
    })(),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(sagaMiddleware);
    },
});
store.subscribe(function () {
    var _a = store.getState(), simulation = _a.simulation, modules = _a.modules;
    saveToLocalStorage({ simulation: simulation, modulesGroups: modules.groups });
});
sagaMiddleware.run(rootSaga);
export default store;
