// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__vvxhU {
  --bs-modal-border-radius: var(--bs-border-radius-sm);
  --bs-modal-header-padding: .75rem 1rem;
  display: block;
}
.Modal_modal__vvxhU .modal-body {
  overflow-y: auto;
  margin-right: 1px;
}
.Modal_modal__vvxhU .modal-body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;
}
.Modal_modal__vvxhU .modal-body::-webkit-scrollbar-track {
  border-radius: 0;
}
.Modal_modal__vvxhU .modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bec2c5;
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/index.module.scss","webpack://./src/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACI,oDAAA;EACA,sCAAA;EACA,cAAA;AADJ;AAGI;ECNA,gBAAA;EACA,iBAAA;ADMJ;ACJI;EACI,aAAA;EACA,cAAA;EACA,6BAAA;ADMR;ACHI;EACI,gBAAA;ADKR;ACFI;EACI,mBAAA;EACA,yBAAA;EACA,gBAAA;ADIR","sourcesContent":["@import '../../styles/mixins.scss';\r\n\r\n.modal {\r\n    --bs-modal-border-radius: var(--bs-border-radius-sm);\r\n    --bs-modal-header-padding: .75rem 1rem;\r\n    display: block;\r\n\r\n    :global(.modal-body) {\r\n        @include custom-scrollbar();\r\n    }\r\n}","@mixin custom-scrollbar {\n    overflow-y: auto;\n    margin-right: 1px;\n\n    &::-webkit-scrollbar {\n        width: 0.5rem;\n        height: 0.5rem;\n        background-color: transparent;\n    }\n\n    &::-webkit-scrollbar-track {\n        border-radius: 0;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        border-radius: 10px;\n        background-color: #bec2c5;\n        border-radius: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__vvxhU`
};
export default ___CSS_LOADER_EXPORT___;
