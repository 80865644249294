var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { PreviewOptionName } from './PreviewOptionName';
import { ProjectType } from './ProjectType';
var ProjectOption;
(function (ProjectOption) {
    ProjectOption["DEFAULT_PREVIEW_OPTION"] = "defaultPreviewOption";
})(ProjectOption || (ProjectOption = {}));
export var ProjectsConfig = (_a = {},
    _a[ProjectType.BRAND_ALERT_MEDSCAPE] = (_b = {},
        _b[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.MOBILE,
        _b),
    _a[ProjectType.CARD] = (_c = {},
        _c[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.DESKTOP,
        _c),
    _a[ProjectType.EMAIL] = (_d = {},
        _d[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.DESKTOP,
        _d),
    _a[ProjectType.MOBILE_ALERT] = (_e = {},
        _e[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.MOBILE,
        _e),
    _a[ProjectType.MOBILE_ALERT_DOXIMITY] = (_f = {},
        _f[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.MOBILE,
        _f),
    _a[ProjectType.MOBILE_ALERT_EPOCRATES] = (_g = {},
        _g[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.MOBILE,
        _g),
    _a[ProjectType.IPAD] = (_h = {},
        _h[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.IPAD,
        _h),
    _a[ProjectType.DISPLAY_PANEL] = (_j = {},
        _j[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.IPAD,
        _j),
    _a[ProjectType.UNIVERSAL_ARTBOARD] = (_k = {},
        _k[ProjectOption.DEFAULT_PREVIEW_OPTION] = PreviewOptionName.DESKTOP,
        _k),
    _a);
