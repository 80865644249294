import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/hooks';
import { actions } from 'store/modules/slice';
import { BundlesContext } from '../context/bundles';
import { ActionType } from './useBundles';
export function useBundle(bundleIndex) {
    var _a = useContext(BundlesContext), bundles = _a.bundles, bundlesDispatch = _a.bundlesDispatch;
    var bundle = bundles[bundleIndex];
    var dispatch = useAppDispatch();
    var toggleAll = useCallback(function () { return bundlesDispatch({ type: ActionType.TOGGLE_ALL, bundleIndex: bundleIndex }); }, [bundleIndex]);
    var toggleModule = useCallback(function (moduleIndex) { return bundlesDispatch({
        type: ActionType.TOGGLE_MODULE,
        bundleIndex: bundleIndex,
        moduleIndex: moduleIndex,
    }); }, [bundleIndex]);
    var toggleGroup = useCallback(function (groupIndex) { return bundlesDispatch({
        type: ActionType.TOGGLE_GROUP,
        bundleIndex: bundleIndex,
        groupIndex: groupIndex,
    }); }, [bundleIndex]);
    var createGroup = useCallback(function (moduleIndexes) {
        dispatch(actions.createGroup({ bundleIndex: bundleIndex, moduleIndexes: moduleIndexes }));
        toast('Module Group Created Successfully', { hideProgressBar: true });
    }, [dispatch, bundleIndex]);
    var removeGroup = useCallback(function (groupIndex) {
        dispatch(actions.removeGroup({ bundleIndex: bundleIndex, groupIndex: groupIndex }));
        toast('Module Group Deleted', {
            hideProgressBar: true,
            style: { backgroundColor: '#6C757D' },
        });
    }, [dispatch, bundleIndex]);
    var shiftModuleInGroup = useCallback(function (groupIndex, moduleIndex, shift) {
        dispatch(actions.shiftModuleInGroup({
            bundleIndex: bundleIndex,
            groupIndex: groupIndex,
            moduleIndex: moduleIndex,
            shift: shift,
        }));
    }, [dispatch, bundleIndex]);
    return {
        bundle: bundle,
        toggleAll: toggleAll,
        toggleModule: toggleModule,
        toggleGroup: toggleGroup,
        createGroup: createGroup,
        removeGroup: removeGroup,
        shiftModuleInGroup: shiftModuleInGroup,
    };
}
