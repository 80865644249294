import React from 'react';
import ModuleBundleDocumentLink from 'components/ModuleBundleDocumentLink';
import styles from '../styles.module.scss';
var Header = function (props) {
    var moduleBundle = props.moduleBundle, majorVersion = props.majorVersion, minorVersion = props.minorVersion;
    var veevaUrl = moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.veevaUrl;
    var moduleBundleName = (moduleBundle === null || moduleBundle === void 0 ? void 0 : moduleBundle.name)
        ? "".concat(moduleBundle.name, " (v").concat(majorVersion, ".").concat(minorVersion, ")")
        : '';
    return (React.createElement("div", { className: styles.contentContainer },
        React.createElement("h4", { className: "p-0 pb-3 m-0", style: { width: 'auto' } }, "Select the channel to see the simulation"),
        React.createElement("div", { className: "border-bottom pb-3" },
            React.createElement(ModuleBundleDocumentLink, { url: veevaUrl, linkText: moduleBundleName }))));
};
export default Header;
