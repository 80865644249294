import _ from 'lodash';
import { DEFAULT_CLASSIFICATION } from 'const';
export var filterModuleBundleLayouts = function (screens) {
    return screens.map(function (screen) {
        var allLayouts = _(screen.sections).flatMap('layouts').value();
        var filteredLayouts = allLayouts.filter(function (layout) {
            return screen.screenTypeDisplayName.toLocaleLowerCase().includes(layout.classification.toLocaleLowerCase());
        });
        var unclassifiedLayouts = filteredLayouts.length
            ? []
            : allLayouts.filter(function (layout) { return layout.classification.toLocaleLowerCase() === DEFAULT_CLASSIFICATION; });
        return {
            id: screen.id,
            name: screen.name,
            displayName: screen.screenTypeDisplayName,
            sectionIds: [],
            layouts: filteredLayouts.length ? filteredLayouts : unclassifiedLayouts,
        };
    });
};
