import classNames from 'classnames';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectActiveScreen } from 'store/simulationSlice';
import styles from './styles.module.scss';
var NavigationScreens = function (_a) {
    var screens = _a.screens, onScreenClick = _a.onScreenClick;
    var activeScreenId = useAppSelector(selectActiveScreen);
    return (React.createElement("div", { className: styles.screens }, screens.map(function (_a, index) {
        var _b;
        var id = _a.id;
        var isScreenActive = activeScreenId ? activeScreenId === id : index === 0;
        return (React.createElement("div", { className: classNames(styles.screen, (_b = {},
                _b[styles.active] = isScreenActive,
                _b)), key: id, onClick: function () { return onScreenClick(id); } }, index + 1));
    })));
};
export default NavigationScreens;
