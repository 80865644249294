var _this = this;
import React from 'react';
import { getSimulationChannelThumbnail } from 'utils';
import styles from './TemplateCard.module.scss';
var TemplateCard = function (_a) {
    var template = _a.template, redirectToChannel = _a.redirectToChannel;
    var resourceType = template.resourceType;
    var Thumbnail = getSimulationChannelThumbnail(resourceType);
    return (React.createElement("div", { onClick: redirectToChannel.bind(_this, template), onKeyUp: function () { return undefined; }, className: styles.TemplateCard },
        React.createElement("div", { className: styles.thumbnail },
            React.createElement(Thumbnail, null)),
        React.createElement("div", { className: styles.details }, template.resourceType)));
};
export default TemplateCard;
