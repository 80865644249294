var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var sortScreensByTemplates = function (screens, templateSections) {
    var sortedScreens = [];
    templateSections.forEach(function (templateSection) {
        var filteredScreens = screens.filter(function (screen) {
            return screen.sectionIds.includes(templateSection.id)
                && !sortedScreens.some(function (sortedScreen) { return sortedScreen.id === screen.id; });
        });
        sortedScreens = __spreadArray(__spreadArray([], sortedScreens, true), filteredScreens, true);
    });
    return sortedScreens;
};
