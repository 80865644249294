import React, { useId } from 'react';
import LayoutPreview from 'components/LayoutPreview';
import styles from '../Modules.module.scss';
export var BundleListItemModule = function (props) {
    var module = props.module, onChange = props.onChange, checked = props.checked, disabled = props.disabled;
    var htmlId = useId();
    return (React.createElement("label", { className: "mb-2 border rounded ".concat(styles.bundlesListItem), htmlFor: htmlId },
        React.createElement("label", { className: "form-check-label title" },
            React.createElement("input", { className: "form-check-input custom-check me-2", type: "checkbox", value: "", id: htmlId, onChange: onChange, checked: Boolean(checked), disabled: disabled }),
            "Module #",
            module.index + 1),
        React.createElement("div", { className: "content" },
            React.createElement("div", { className: "content-row" },
                React.createElement("div", { className: "content-controls" }),
                React.createElement(LayoutPreview, { documentId: module.id, alt: module.classification })))));
};
