var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
var initialState = {
    moduleBundle: {
        name: '',
        documentId: 0,
        majorVersion: 0,
        minorVersion: 0,
        resourceType: '',
        veevaUrl: '',
        channels: [],
    },
    status: SAGA_STATUS.IDLE,
    error: '',
};
export var channelsSlice = createSlice({
    name: 'channels',
    initialState: initialState,
    reducers: {
        getModuleBundleChannelsDataStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        getModuleBundleChannelsDataSuccess: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.moduleBundle = action.payload;
        },
        getModuleBundleChannelsDataError: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.error = action.payload;
        },
    },
});
export var selectModuleBundleDataLoadingStatus = function (state) {
    return state.channels.status === SAGA_STATUS.PENDING;
};
export var getModuleBundleChannelsDataRequest = createAction('getModuleBundleChannelsDataRequest');
export var selectModuleBundle = function (state) { return state.channels.moduleBundle; };
export var getModuleBundleChannelsDataStart = (_a = channelsSlice.actions, _a.getModuleBundleChannelsDataStart), getModuleBundleChannelsDataSuccess = _a.getModuleBundleChannelsDataSuccess, getModuleBundleChannelsDataError = _a.getModuleBundleChannelsDataError;
export default channelsSlice.reducer;
