export var API = Object.freeze({
    AUTHORIZE_VEEVA_SESSION: '/authorizeVeevaSession',
    GET_USER_AUTH_TYPE: '/getUserAuthType',
    GET_USER_INFO_FROM_VEEVA: '/getUserInfoFromVeeva',
    GET_DATA_FROM_VEEVA: '/getDataFromVeeva',
    GET_DOCUMENT_DATA_FROM_VEEVA: '/getDocumentDataFromVeeva',
    GET_MODULES_FROM_VEEVA: '/getModulesFromVeeva',
    SEND_RECIPE: '/sendRecipe',
    GET_MODULE_BUNDLE_CHANNELS: '/reviewers-mode/channels',
    GET_MODULE_BUNDLE_SIMULATION: '/reviewers-mode/modules',
    GET_RENDITION: '/rendition',
});
export var CONTENT_TYPE;
(function (CONTENT_TYPE) {
    CONTENT_TYPE["COUNTRY"] = "country";
    CONTENT_TYPE["PRODUCT_FAMILY"] = "productFamily";
    CONTENT_TYPE["RESOURCE_TYPE_BY_COUNTRY"] = "resourceTypeByCountry";
    CONTENT_TYPE["DELIVERY_METHOD"] = "methodOfDelivery";
    CONTENT_TYPE["DEPLOYMENT_SUPPLIER"] = "deploymentSupplierName";
    CONTENT_TYPE["RESOURCE_TYPE"] = "resourceType";
})(CONTENT_TYPE || (CONTENT_TYPE = {}));
export var AUTH_TYPE;
(function (AUTH_TYPE) {
    AUTH_TYPE["SSO"] = "sso";
    AUTH_TYPE["PASSWORD"] = "password";
})(AUTH_TYPE || (AUTH_TYPE = {}));
var API_DOMAIN = {
    dev: 'api-generative-marketingpersonalization.dev.msd-gmp.com',
    qa: 'api-generative-marketingpersonalization-qa.dev.msd-gmp.com',
    uat: 'api-generative-marketingpersonalization.uat.msd-gmp.com',
    prod: 'api-generative-marketingpersonalization.msd-gmp.com',
};
export var OAUTH2_URL = {
    dev: 'https://pingfed-ut.merck.com/as/authorization.oauth2',
    uat: 'https://pingfed-ut.merck.com/as/authorization.oauth2',
    prod: 'https://pingfed.merck.com/as/authorization.oauth2',
};
var stage = process.env.REACT_APP_STAGE;
if (!stage) {
    throw Error('Undefined REACT_APP_STAGE');
}
export var BACKEND_URL = "https://".concat(API_DOMAIN[stage]);
