var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import styles from '../Modules.module.scss';
import { BundleListItemModule } from './BundleListItemModule';
var ERROR_MIN_NUMBER = 'Select at least 2 modules to create a module group';
export var GroupModal = function (props) {
    var isOpen = props.isOpen, onClose = props.onClose, modules = props.modules, onCreate = props.onCreate;
    var _a = useState([]), selected = _a[0], setSelected = _a[1];
    var _b = useState(undefined), error = _b[0], setError = _b[1];
    useEffect(function () {
        setSelected([]);
        setError(undefined);
    }, [modules, isOpen]);
    var createOnChangeHandler = function (idx) { return function () {
        setSelected(function (vals) {
            var updated = __spreadArray([], vals, true);
            updated[idx] = !updated[idx];
            return updated;
        });
        setError(undefined);
    }; };
    var createGroupHandler = function () {
        var indexes = selected.flatMap(function (item, idx) { return item ? [idx] : []; });
        if (indexes.length < 2) {
            setError(ERROR_MIN_NUMBER);
            return;
        }
        onCreate(indexes);
        onClose();
    };
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, size: "xl", title: "Create Module Group" },
        React.createElement("div", { className: "modal-body" },
            React.createElement("div", { className: "mb-3 ".concat(styles.moduleThumbnails) }, modules.map(function (module, idx) { return (React.createElement(BundleListItemModule, { key: "".concat(module.index, "-").concat(module.layoutId), module: module, onChange: createOnChangeHandler(idx), checked: selected[idx] })); }))),
        React.createElement("div", { className: "modal-footer" },
            error && (React.createElement("div", { className: "text-danger mx-3 small" }, error)),
            React.createElement(Button, { label: "Create", type: "button", onClick: createGroupHandler }))));
};
