/* eslint-disable max-len */
import React from 'react';
var DefaultTemplate = function () {
    return (React.createElement("svg", { width: "80", height: "80", viewBox: "0 0 80 80", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_661_152)" },
            React.createElement("rect", { width: "80", height: "80", fill: "white", fillOpacity: "0.01" }),
            React.createElement("g", { clipPath: "url(#clip1_661_152)" },
                React.createElement("path", { d: "M54.9996 12.4992C54.9993 9.56635 56.0302 6.72677 57.912 4.47725C59.7938 2.22772 62.4066 0.711507 65.2934 0.193875C68.1802 -0.323757 71.1571 0.190157 73.7033 1.6457C76.2494 3.10125 78.2027 5.40574 79.2214 8.15598C80.2401 10.9062 80.2592 13.9271 79.2756 16.6901C78.2919 19.453 76.3681 21.7821 73.8406 23.2699C71.3132 24.7577 68.343 25.3094 65.4499 24.8284C62.5568 24.3475 59.9248 22.8646 58.0146 20.6392L24.4246 36.2392C25.1968 38.6864 25.1968 41.312 24.4246 43.7592L58.0146 59.3592C60.0338 57.0108 62.8529 55.4957 65.9256 55.1074C68.9982 54.7192 72.1056 55.4854 74.6455 57.2577C77.1854 59.0299 78.977 61.6819 79.6729 64.6998C80.3689 67.7177 79.9198 70.8865 78.4126 73.5921C76.9053 76.2977 74.4474 78.3474 71.515 79.344C68.5827 80.3406 65.3847 80.2131 62.541 78.9862C59.6973 77.7594 57.4103 75.5205 56.1232 72.7036C54.8361 69.8866 54.6406 66.6921 55.5746 63.7392L21.9846 48.1392C20.3218 50.077 18.1054 51.4592 15.6335 52.0996C13.1617 52.7401 10.5529 52.6081 8.15831 51.7216C5.76367 50.835 3.698 49.2363 2.23915 47.1406C0.780312 45.0449 -0.00170898 42.5527 -0.00170898 39.9992C-0.00170898 37.4457 0.780312 34.9535 2.23915 32.8578C3.698 30.762 5.76367 29.1634 8.15831 28.2768C10.5529 27.3902 13.1617 27.2583 15.6335 27.8988C18.1054 28.5392 20.3218 29.9213 21.9846 31.8592L55.5746 16.2592C55.1923 15.0425 54.9984 13.7745 54.9996 12.4992V12.4992Z", fill: "url(#paint0_linear_661_152)" }))),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_661_152", x1: "39.999", y1: "-0.00238037", x2: "39.999", y2: "80.0088", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#00857C" }),
                React.createElement("stop", { offset: "1", stopColor: "#29AAA1" })),
            React.createElement("clipPath", { id: "clip0_661_152" },
                React.createElement("rect", { width: "80", height: "80", fill: "white" })),
            React.createElement("clipPath", { id: "clip1_661_152" },
                React.createElement("rect", { width: "80", height: "80", fill: "white" })))));
};
export default DefaultTemplate;
