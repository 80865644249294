import React, { forwardRef } from 'react';
import styles from '../Filters.module.scss';
// eslint-disable-next-line react/display-name
var JobZeroContent = forwardRef(function (props, ref) {
    var jobZeroData = props.jobZeroData, lastSelectedDocumentId = props.lastSelectedDocumentId, onJobZeroSelectHandler = props.onJobZeroSelectHandler;
    return (React.createElement(React.Fragment, null, jobZeroData.map(function (item) { return (React.createElement("tr", { key: item.document_number__v, ref: lastSelectedDocumentId === item.id ? ref : null },
        React.createElement("td", null, item.document_number__v),
        React.createElement("td", null, item.name__v),
        React.createElement("td", null,
            React.createElement("div", { className: "d-flex align-items-center justify-content-between" },
                item.bundles[0] ? item.bundles[0].name__v : 'No bundles found',
                item.bundles[0] ? React.createElement("button", { className: "flex-shrink-0 ".concat(styles.link), onClick: function () {
                        onJobZeroSelectHandler(item.id, item.bundles[0].id);
                    } }, "Select") : '')))); })));
});
export default JobZeroContent;
