var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { replaceGroupsInBundle } from 'store/modules/helpers';
export function validateSelection(bundles) {
    return bundles.some(function (bundle) {
        var moduleSelected = bundle.modules.some(function (module) { return module.selected; });
        var groupSelected = bundle.groups.some(function (group) { return group.selected; });
        return moduleSelected || groupSelected;
    });
}
function createBundleWithSelection(bundle) {
    var modules = bundle.modules, groups = bundle.groups;
    var availableModules = modules.filter(function (module) { return !module.disabled; }).length;
    var selectedModules = modules.filter(function (module) { return module.selected; }).length;
    var availableGroups = groups.filter(function (group) { return group; }).length;
    var selectedGroups = groups.filter(function (group) { return group.selected; }).length;
    return __assign(__assign({}, bundle), { modules: modules.map(function (module) { return (__assign({}, module)); }), groups: groups.map(function (group) { return (__assign({}, group)); }), selectedCount: selectedModules + selectedGroups, availableCount: availableModules + availableGroups });
}
export function convertBundleWithSelectionToBundle(bundle) {
    var modules = bundle.modules, groups = bundle.groups, selectedCount = bundle.selectedCount, // eslint-disable-line no-unused-vars
    availableCount = bundle.availableCount, // eslint-disable-line no-unused-vars
    other = __rest(bundle, ["modules", "groups", "selectedCount", "availableCount"]);
    return __assign(__assign({}, other), { modules: modules.map(function (module) { return (__assign({}, module)); }), groups: groups.map(function (group) { return (__assign({}, group)); }) });
}
function replaceBundle(bundles, bundleIndex, newBundle) {
    var newState = __spreadArray([], bundles, true);
    newState[bundleIndex] = createBundleWithSelection(newBundle);
    return newState;
}
function replaceModuleInBundle(bundle, moduleIndex, module) {
    var modules = __spreadArray([], bundle.modules, true);
    modules[moduleIndex] = module;
    return __assign(__assign({}, bundle), { modules: modules });
}
export function updateBundles(current, updated) {
    return updated.map(function (bundle) {
        var _a, _b;
        var currentModules = ((_a = current[bundle.index]) === null || _a === void 0 ? void 0 : _a.modules) || bundle.modules;
        var modules = bundle.modules.map(function (module) {
            var _a, _b;
            return __assign(__assign({}, module), { selected: Boolean((_a = currentModules === null || currentModules === void 0 ? void 0 : currentModules[module.index]) === null || _a === void 0 ? void 0 : _a.selected), disabled: Boolean((_b = currentModules === null || currentModules === void 0 ? void 0 : currentModules[module.index]) === null || _b === void 0 ? void 0 : _b.disabled) });
        });
        var currentGroups = ((_b = current[bundle.index]) === null || _b === void 0 ? void 0 : _b.groups) || bundle.groups;
        var groups = bundle.groups.map(function (group) {
            var _a;
            return __assign(__assign({}, group), { selected: Boolean((_a = currentGroups === null || currentGroups === void 0 ? void 0 : currentGroups[group.index]) === null || _a === void 0 ? void 0 : _a.selected) });
        });
        return createBundleWithSelection(__assign(__assign({}, bundle), { modules: modules, groups: groups }));
    });
}
export function setModuleSelection(bundles, bundleIndex, moduleIndex, isSelected) {
    var module = bundles[bundleIndex].modules[moduleIndex];
    if (!module) {
        return bundles;
    }
    var updatedBundles = bundles;
    bundles.forEach(function (bundle) {
        var updatedBundle = bundle;
        bundle.modules.forEach(function (item) {
            if (item === module) {
                var newModule = __assign(__assign({}, item), { selected: isSelected, disabled: false });
                updatedBundle = replaceModuleInBundle(updatedBundle, item.index, newModule);
                return;
            }
            if (item.id !== module.id || item.disabled === isSelected) {
                return;
            }
            var updatedModule = __assign(__assign({}, item), { disabled: isSelected || item.disabled, selected: isSelected ? false : item.selected });
            updatedBundle = replaceModuleInBundle(updatedBundle, item.index, updatedModule);
        });
        if (updatedBundle !== bundle) {
            updatedBundles = replaceBundle(updatedBundles, bundle.index, updatedBundle);
        }
    });
    return updatedBundles;
}
export function setGroupSelection(bundles, bundleIndex, groupIndex, isSelected) {
    var _a;
    var group = (_a = bundles[bundleIndex]) === null || _a === void 0 ? void 0 : _a.groups[groupIndex];
    if (!group || group.selected === isSelected) {
        return bundles;
    }
    var updatedGroups = bundles[bundleIndex].groups.map(function (i) { return i; });
    updatedGroups[groupIndex] = __assign(__assign({}, group), { selected: isSelected });
    return replaceBundle(bundles, bundleIndex, replaceGroupsInBundle(bundles[bundleIndex], updatedGroups));
}
export function isEntireBundleSelected(bundle) {
    if (!bundle) {
        return false;
    }
    var modules = bundle.modules, groups = bundle.groups;
    var allModulesSelected = modules.filter(function (module) { return !module.disabled; }).every(function (module) { return module.selected; });
    var allGroupsSelected = groups.every(function (group) { return group.selected; });
    return allModulesSelected && allGroupsSelected;
}
export function setEntireBundleSelection(bundles, bundleIndex, isSelected) {
    var bundle = bundles[bundleIndex];
    if (!bundle) {
        return bundles;
    }
    var updatedState = bundle.modules.reduce(function (acc, module) { return acc[bundleIndex].modules[module.index].disabled
        ? acc
        : setModuleSelection(acc, bundleIndex, module.index, isSelected); }, bundles);
    updatedState = bundle.groups.reduce(function (acc, group) { return setGroupSelection(acc, bundleIndex, group.index, isSelected); }, updatedState);
    return updatedState;
}
