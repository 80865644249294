// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tab_button__c7mMW {
  text-align: left;
  border: none;
  border-radius: 5px;
  background-color: white;
}
.Tab_button__c7mMW:hover {
  background-color: #f8f9fa;
}
.Tab_button__c7mMW:active {
  background-color: #E9F5F1;
}

.Tab_active__9nXkE {
  background-color: #E9F5F1;
}`, "",{"version":3,"sources":["webpack://./src/components/Tab.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".button {\n  text-align: left;\n  border: none;\n  border-radius: 5px;\n  background-color: white;\n\n  &:hover {\n    background-color: #f8f9fa;\n  }\n\n  &:active {\n    background-color: #E9F5F1;\n  }\n}\n\n.active {\n  background-color: #E9F5F1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Tab_button__c7mMW`,
	"active": `Tab_active__9nXkE`
};
export default ___CSS_LOADER_EXPORT___;
