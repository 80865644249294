import React, { useLayoutEffect, useRef } from 'react';
import Accordion from 'components/Accordion';
import Artboard from 'components/Artboard';
import NavigationScreens from 'components/NavigationScreens';
import store from 'store';
import { useAppDispatch } from 'store/hooks';
import { SimulationScrollSide } from 'store/simulation';
import { setScrollPosition, selectScrollPositions, addLayoutOnSection, removeLayoutOnSection, } from 'store/simulationSlice';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
import styles from '../styles.module.scss';
var SimulationData = function (_a) {
    var template = _a.template, moduleBundleLeftPanel = _a.moduleBundleLeftPanel;
    var dispatch = useAppDispatch();
    var screensRefs = useRef({});
    var onScreenClick = function (screenId) {
        var scrollToScreen = screensRefs.current[screenId];
        scrollToScreen === null || scrollToScreen === void 0 ? void 0 : scrollToScreen.scrollIntoView({ behavior: 'smooth' });
    };
    var clickOnRendition = function (screen, layout) {
        for (var _i = 0, _a = screen.sectionIds; _i < _a.length; _i++) {
            var sectionId = _a[_i];
            var action = layout
                ? addLayoutOnSection({ entityId: sectionId, layout: layout, shouldRemoveLayout: true })
                : removeLayoutOnSection({ entityId: sectionId });
            dispatch(action);
        }
        eventEmitter.emit(EVENTS.SCROLL_SECTION_RIGHT, screen.sectionIds[0]);
    };
    var onScroll = function (side, event) {
        dispatch(setScrollPosition({ side: side, value: event.currentTarget.scrollTop }));
    };
    var onChangeLayoutVariant = function (section, layout, screen) {
        for (var _i = 0, _a = screen.sectionIds; _i < _a.length; _i++) {
            var sectionId = _a[_i];
            dispatch(layout
                ? addLayoutOnSection({ entityId: sectionId, layout: layout })
                : removeLayoutOnSection({ entityId: sectionId }));
        }
    };
    var leftPanelRef = useRef(null);
    var rightPanelRef = useRef(null);
    useLayoutEffect(function () {
        var scrollPositions = selectScrollPositions(store.getState());
        // wait two seconds for all images to load.
        // should be removed when heights of images would be saved to store and persisted
        setTimeout(function () {
            var _a, _b;
            (_a = leftPanelRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: scrollPositions.left });
            (_b = rightPanelRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({ top: scrollPositions.right });
        }, 2000);
    }, []);
    return (React.createElement("div", { className: styles.Container },
        React.createElement("div", { ref: leftPanelRef, onScroll: function (event) { return onScroll(SimulationScrollSide.LEFT, event); }, className: styles.LeftPanel },
            React.createElement(Accordion, { screens: moduleBundleLeftPanel, scrollContainer: leftPanelRef, onItemClick: clickOnRendition, onClearButtonClick: function (section) { return clickOnRendition(section, null); } })),
        React.createElement("div", { className: styles.RightPanel },
            React.createElement(NavigationScreens, { screens: template === null || template === void 0 ? void 0 : template.screens, onScreenClick: onScreenClick }),
            React.createElement(Artboard, { screens: template === null || template === void 0 ? void 0 : template.screens, projectType: template === null || template === void 0 ? void 0 : template.projectType, screensRefs: screensRefs, onScroll: function (event) { return onScroll(SimulationScrollSide.RIGHT, event); }, onCnahgeLayoutVariant: onChangeLayoutVariant, ref: rightPanelRef }))));
};
export default SimulationData;
