import classNames from 'classnames';
import React from 'react';
import variables from 'styles/_variables.module.scss';
import DownAngleArrow from 'assets/images/DownAngleArrow';
import RightAngleArrow from 'assets/images/RightAngleArrow';
import Button from 'components/Button/Button';
import { CLEAR_ZONE_BUTTON } from 'const';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
import styles from './AccordionPanel.module.scss';
var AccordionPanel = React.forwardRef(function (_a, ref) {
    var _b;
    var label = _a.label, sectionId = _a.sectionId, isActive = _a.isActive, isClearButtonShown = _a.isClearButtonShown, children = _a.children, onClick = _a.onClick, onClearButtonClick = _a.onClearButtonClick;
    var ToggleIcon = isActive ? DownAngleArrow : RightAngleArrow;
    var onMouseOver = function () {
        eventEmitter.emit(EVENTS.FOCUS_SECTION_RIGHT, sectionId);
    };
    var onMouseOut = function () {
        eventEmitter.emit(EVENTS.FOCUS_SECTION_RIGHT, '');
    };
    var handleClearButtonClick = function (event) {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onClearButtonClick();
    };
    return (React.createElement("div", { onMouseOver: onMouseOver, onMouseOut: onMouseOut, ref: ref },
        React.createElement("div", { className: classNames(styles.panel, (_b = {},
                _b[styles.active] = isActive,
                _b)), onClick: onClick },
            React.createElement(ToggleIcon, null),
            React.createElement("span", { className: styles.label }, label),
            isClearButtonShown && (React.createElement(Button, { type: 'button', isDisabled: false, label: CLEAR_ZONE_BUTTON, styleOverride: {
                    backgroundColor: variables.clearSectionButtonColor,
                    marginBottom: 0,
                    padding: '0 5px',
                    fontSize: variables.buttonFontSize,
                    borderRadius: variables.buttonBorderRadius,
                }, onClick: handleClearButtonClick }))),
        isActive && children));
});
AccordionPanel.displayName = 'AccordionPanel';
export default AccordionPanel;
