import React from 'react';
import ReactDOM from 'react-dom';
import Modules from 'pages/Modules/Modules';
import styles from './ModulesModal.module.scss';
var Backdrop = function (_a) {
    var onModalClose = _a.onModalClose;
    return React.createElement("div", { className: styles.backdrop, onClick: onModalClose, onKeyUp: function () { return undefined; } });
};
var ModalOverlay = function (props) {
    var jobZeroId = props.jobZeroId, moduleBundleId = props.moduleBundleId, onModalClose = props.onModalClose, onFormSubmit = props.onFormSubmit, isNoMoreToAdd = props.isNoMoreToAdd;
    return (React.createElement("div", { className: styles.modal },
        React.createElement("div", { className: styles.modalBody },
            React.createElement("button", { type: "button", className: "".concat(styles.closeButton, " btn-close"), "aria-label": "Close", onClick: onModalClose }),
            React.createElement(Modules, { modal: { jobZeroId: jobZeroId, moduleBundleId: moduleBundleId, onFormSubmit: onFormSubmit, isNoMoreToAdd: isNoMoreToAdd } }))));
};
var ModulesModal = function (props) {
    var jobZeroId = props.jobZeroId, moduleBundleId = props.moduleBundleId, onModalClose = props.onModalClose, onFormSubmit = props.onFormSubmit, isNoMoreToAdd = props.isNoMoreToAdd;
    return (React.createElement(React.Fragment, null,
        ReactDOM.createPortal(React.createElement(Backdrop, { onModalClose: onModalClose }), document.getElementById('backdrop-root')),
        ReactDOM.createPortal(React.createElement(ModalOverlay, { jobZeroId: jobZeroId, moduleBundleId: moduleBundleId, onModalClose: onModalClose, onFormSubmit: onFormSubmit, isNoMoreToAdd: isNoMoreToAdd }), document.getElementById('modal-root'))));
};
export default ModulesModal;
