import React, { forwardRef, useId } from 'react';
import BiChevronDown from 'assets/images/bi-chevron-down.svg';
import BiChevronUp from 'assets/images/bi-chevron-up.svg';
import BiExclamationCircle from 'assets/images/bi-exclamation-circle.svg';
import BiLayersFill from 'assets/images/bi-layers-fill.svg';
import BiTrash from 'assets/images/bi-trash.svg';
import LayoutPreview from 'components/LayoutPreview';
import styles from '../Modules.module.scss';
export var BundleListItemGroup = forwardRef(function (props, ref) {
    var group = props.group, onChange = props.onChange, onRemove = props.onRemove, onShitfModule = props.onShitfModule, checked = props.checked;
    var modules = group.modules;
    var htmlId = useId();
    return (React.createElement("label", { className: "mb-2 ".concat(styles.bundlesListItem, " border rounded"), htmlFor: htmlId, ref: ref },
        React.createElement("label", { className: "form-check-label title" },
            React.createElement("input", { className: "form-check-input custom-check me-2", type: "checkbox", value: "", id: htmlId, onChange: onChange, checked: Boolean(checked) }),
            React.createElement(BiLayersFill, { className: 'me-2 flex-shrink-0' }),
            React.createElement("div", { className: "title-label" }, modules.map(function (module) { return "Module #".concat(module.index + 1); }).join(' + ')),
            group.hasDuplicates && (React.createElement("div", { className: "text-warning" },
                React.createElement(BiExclamationCircle, { className: 'me-2 mb-1' }),
                "Such module group has already been created")),
            React.createElement("div", { className: "flex-grow-1" }),
            React.createElement("button", { className: "btn btn-sm btn-outline-secondary", onClick: onRemove, type: "button" },
                React.createElement(BiTrash, null))),
        React.createElement("div", { className: "content" }, modules.map(function (module, idx) {
            var upDisabled = idx <= 0;
            var downDisabled = idx + 1 >= modules.length;
            return (React.createElement("div", { className: "content-row", key: module.index },
                React.createElement("div", { className: "content-controls" },
                    React.createElement("div", { className: "btn-group bg-white shadow-sm", role: "group" },
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-light custom-button", disabled: downDisabled, onClick: function (event) {
                                event.preventDefault();
                                event.stopPropagation();
                                onShitfModule(module.index, 1);
                            } },
                            React.createElement(BiChevronDown, null)),
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-light custom-button", disabled: upDisabled, onClick: function (event) {
                                event.preventDefault();
                                event.stopPropagation();
                                onShitfModule(module.index, -1);
                            } },
                            React.createElement(BiChevronUp, { className: "mb-1" })))),
                React.createElement(LayoutPreview, { documentId: module.id, alt: module.classification })));
        }))));
});
BundleListItemGroup.displayName = 'BundlesListItemGroup';
