var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Section from 'components/Section';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMergedSimulationCombinations, setActiveScreen } from 'store/simulationSlice';
import styles from './styles.module.scss';
var SECTION_WIDTH = 800;
var INITIAL_LOAD_TIME_TRESHHOLD = 2000;
var INTERSECTION_RATIO_MORE_THAN_HALF_SCREEN = 0.5;
var Screen = forwardRef(function (_a, ref) {
    var screenId = _a.screenId, sections = _a.sections, scrollContainer = _a.scrollContainer, onChangeLayoutVariant = _a.onChangeLayoutVariant;
    var dispatch = useAppDispatch();
    var mergedSimulationCombinations = useAppSelector(selectMergedSimulationCombinations);
    var screenRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, function () { return screenRef.current; }, []);
    useEffect(function () {
        var intersectionObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                // on initial load we should take entry with the big intersection ratio as this is our visible screen
                if (entry.time < INITIAL_LOAD_TIME_TRESHHOLD
                    && entry.intersectionRatio < INTERSECTION_RATIO_MORE_THAN_HALF_SCREEN) {
                    return;
                }
                // during scroll page currently intersecting is the one we need
                if (entry.isIntersecting) {
                    dispatch(setActiveScreen(screenId));
                }
            });
        }, {
            rootMargin: '-35% 0px -60% 0px',
        });
        if (screenRef.current) {
            intersectionObserver.observe(screenRef.current);
        }
        return function () {
            if (screenRef.current) {
                intersectionObserver.unobserve(screenRef.current);
            }
        };
    }, [screenRef]);
    return (React.createElement("div", { className: styles.screen, ref: screenRef },
        sections.map(function (section) {
            var id = section.id, displayName = section.displayName, layouts = section.layouts;
            var matchedScreens = mergedSimulationCombinations.filter(function (template) { return template.sectionIds.includes(id); });
            var layoutVariants = matchedScreens.length
                ? matchedScreens.reduce(function (result, matchedScreen) {
                    result = __spreadArray(__spreadArray([], result, true), matchedScreen.layouts, true);
                    return result;
                }, [])
                : [];
            return (React.createElement(Section, { key: id, id: id, displayName: displayName, layouts: layouts, layoutVariants: layoutVariants, width: SECTION_WIDTH, scrollContainer: scrollContainer, screenId: matchedScreens.length ? matchedScreens[0].id : '', matchedScreens: matchedScreens, onCnahgeLayoutVariant: onChangeLayoutVariant.bind(null, section) }));
        }),
        React.createElement("div", { className: styles.backdrop },
            React.createElement("div", { style: { width: SECTION_WIDTH } }))));
});
Screen.displayName = 'Screen';
export default Screen;
