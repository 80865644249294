var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SAGA_STATUS } from 'const';
import { applyLayoutStatus } from 'utils';
export var initialState = {
    searchString: '',
    moduleBundleData: {
        moduleBundle: {
            id: '',
            name: '',
            resourceType: '',
            projectType: '',
            veevaUrl: '',
            screens: [],
        },
        status: SAGA_STATUS.IDLE,
        error: '',
    },
    templateData: {
        template: {
            id: '',
            name: '',
            resourceType: '',
            projectType: '',
            veevaUrl: '',
            screens: [],
        },
        status: SAGA_STATUS.IDLE,
        error: '',
    },
    mergedSimulationCombinations: [],
    activeScreenId: '',
    activePanels: {},
    scrollPositions: {
        left: 0,
        right: 0,
    },
    templateReplacedLayouts: {},
};
export var simulationSlice = createSlice({
    name: 'simulation',
    initialState: initialState,
    reducers: {
        resetSimulationState: function () { return initialState; },
        getModuleBundleTemplateDataStart: function (state) {
            state.moduleBundleData.status = SAGA_STATUS.PENDING;
            state.moduleBundleData.error = '';
        },
        getModuleBundleTemplateDataSuccess: function (state, action) {
            state.moduleBundleData.status = SAGA_STATUS.IDLE;
            var _a = action.payload, error = _a.error, _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.name, name = _c === void 0 ? '' : _c, _d = _a.veevaUrl, veevaUrl = _d === void 0 ? '' : _d;
            if (error) {
                state.moduleBundleData.error = error;
                state.moduleBundleData.moduleBundle = __assign(__assign({}, initialState.moduleBundleData.moduleBundle), { id: id, name: name, veevaUrl: veevaUrl });
            }
            else {
                state.moduleBundleData.moduleBundle = action.payload;
            }
        },
        getModuleBundleTemplateDataError: function (state, action) {
            state.moduleBundleData.status = SAGA_STATUS.IDLE;
            state.moduleBundleData.error = action.payload;
        },
        getTemplateDataStart: function (state) {
            state.templateData.status = SAGA_STATUS.PENDING;
            state.templateData.error = '';
        },
        getTemplateDataSuccess: function (state, action) {
            state.templateData.status = SAGA_STATUS.IDLE;
            state.templateData.template = action.payload;
        },
        getTemplateDataError: function (state, action) {
            state.templateData.status = SAGA_STATUS.IDLE;
            state.templateData.error = action.payload;
        },
        setMergedSimulationCombinations: function (state, action) {
            state.mergedSimulationCombinations = action.payload;
        },
        addLayoutOnSection: function (state, action) {
            var _a = action.payload, entityId = _a.entityId, layout = _a.layout, _b = _a.shouldRemoveLayout, shouldRemoveLayout = _b === void 0 ? false : _b;
            for (var _i = 0, _c = state.templateData.template.screens; _i < _c.length; _i++) {
                var templeateScreen = _c[_i];
                var templateSection = templeateScreen.sections.find(function (_a) {
                    var id = _a.id;
                    return id === entityId;
                });
                if (templateSection) {
                    var stateScreens = state.mergedSimulationCombinations.filter(function (simulationCombination) { return simulationCombination.sectionIds.includes(entityId); });
                    var stateScreensLayouts = _(stateScreens).flatMap('layouts').value();
                    stateScreensLayouts.forEach(function (stateScreensLayout) {
                        stateScreensLayout.active = false;
                    });
                    var indexOfSection = templateSection.layouts.findIndex((function (_a) {
                        var id = _a.id;
                        return id === layout.id;
                    }));
                    if (indexOfSection === -1) {
                        if (!state.templateReplacedLayouts[entityId]) {
                            state.templateReplacedLayouts[entityId] = __spreadArray([], templateSection.layouts, true);
                        }
                        templateSection.layouts = [layout];
                        var currentLayout = stateScreensLayouts.find(function (screenLayout) { return screenLayout.id === layout.id; });
                        if (currentLayout) {
                            currentLayout.active = true;
                        }
                    }
                    else if (shouldRemoveLayout) {
                        templateSection.layouts = __spreadArray([], state.templateReplacedLayouts[entityId], true);
                        delete state.templateReplacedLayouts[entityId];
                    }
                }
            }
            return state;
        },
        removeLayoutOnSection: function (state, action) {
            var entityId = action.payload.entityId;
            for (var _i = 0, _a = state.templateData.template.screens; _i < _a.length; _i++) {
                var screen = _a[_i];
                var templateSection = screen.sections.find(function (_a) {
                    var id = _a.id;
                    return id === entityId;
                });
                if (templateSection) {
                    var stateScreens = state.mergedSimulationCombinations.filter(function (simulationCombination) { return simulationCombination.sectionIds.includes(entityId); });
                    stateScreens.forEach(function (stateScreen) { return stateScreen.layouts = applyLayoutStatus(stateScreen.layouts, false); });
                    if (state.templateReplacedLayouts[entityId]) {
                        templateSection.layouts = __spreadArray([], state.templateReplacedLayouts[entityId], true);
                        delete state.templateReplacedLayouts[entityId];
                    }
                }
            }
            return state;
        },
        removeAllLayouts: function (state) {
            for (var _i = 0, _a = state.templateData.template.screens; _i < _a.length; _i++) {
                var screen = _a[_i];
                screen.sections.forEach(function (section) {
                    var replacedLayouts = state.templateReplacedLayouts[section.id];
                    if (replacedLayouts) {
                        section.layouts = __spreadArray([], replacedLayouts, true);
                    }
                });
            }
            state.mergedSimulationCombinations.forEach(function (simulationCombination) {
                simulationCombination.layouts = applyLayoutStatus(simulationCombination.layouts, false);
            });
            state.templateReplacedLayouts = {};
            return state;
        },
        setActiveScreen: function (state, action) {
            state.activeScreenId = action.payload;
        },
        setSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        toggleActivePanels: function (state, action) {
            var _a = action.payload, screenId = _a.screenId, activeState = _a.activeState;
            var currentState = Boolean(state.activePanels[screenId]);
            state.activePanels[screenId] = typeof activeState === 'boolean' ? activeState : !currentState;
        },
        setScrollPosition: function (state, action) {
            var _a = action.payload, side = _a.side, value = _a.value;
            state.scrollPositions[side] = value;
        },
    },
});
export var selectActivePanels = function (_a) {
    var simulation = _a.simulation;
    return simulation.activePanels;
};
export var selectSimulationDataLoadingStatus = function (_a) {
    var simulation = _a.simulation;
    return simulation.moduleBundleData.status === SAGA_STATUS.PENDING
        || simulation.templateData.status === SAGA_STATUS.PENDING;
};
export var areSearchParamsSame = function (searchString) { return function (_a) {
    var simulation = _a.simulation;
    return simulation.searchString === searchString;
}; };
export var selectErrorTemplateData = function (_a) {
    var simulation = _a.simulation;
    return simulation.templateData.error;
};
export var selectErrorModuleBundleData = function (_a) {
    var simulation = _a.simulation;
    return simulation.moduleBundleData.error;
};
export var getModuleBundleTemplateDataRequest = createAction('getModuleBundleTemplateDataRequest');
export var getTemplateDataRequest = createAction('getTemplateDataRequest');
export var selectModuleBundle = function (state) { return state.simulation.moduleBundleData.moduleBundle; };
export var selectTemplate = function (state) { return state.simulation.templateData.template; };
export var selectTemplateHasLayouts = function (state) {
    var _a, _b;
    return (_b = (_a = state.simulation.templateData) === null || _a === void 0 ? void 0 : _a.template) === null || _b === void 0 ? void 0 : _b.screens.some(function (_a) {
        var sections = _a.sections;
        return sections.some(function (_a) {
            var layouts = _a.layouts;
            return layouts.length;
        });
    });
};
export var selectMergedSimulationCombinations = function (state) { return !selectSimulationDataLoadingStatus(state)
    ? state.simulation.mergedSimulationCombinations : []; };
export var selectActiveScreen = function (state) { return state.simulation.activeScreenId; };
export var selectScreens = function (state) { return state.simulation.templateData.template.screens; };
export var selectScrollPositions = function (state) { return state.simulation.scrollPositions; };
export var selectTemplateReplacedLayouts = function (state) { return state.simulation.templateReplacedLayouts; };
export var getModuleBundleTemplateDataStart = (_a = simulationSlice.actions, _a.getModuleBundleTemplateDataStart), getModuleBundleTemplateDataSuccess = _a.getModuleBundleTemplateDataSuccess, getModuleBundleTemplateDataError = _a.getModuleBundleTemplateDataError, getTemplateDataStart = _a.getTemplateDataStart, getTemplateDataSuccess = _a.getTemplateDataSuccess, getTemplateDataError = _a.getTemplateDataError, setMergedSimulationCombinations = _a.setMergedSimulationCombinations, addLayoutOnSection = _a.addLayoutOnSection, removeLayoutOnSection = _a.removeLayoutOnSection, removeAllLayouts = _a.removeAllLayouts, setActiveScreen = _a.setActiveScreen, setSearchString = _a.setSearchString, resetSimulationState = _a.resetSimulationState, toggleActivePanels = _a.toggleActivePanels, setScrollPosition = _a.setScrollPosition;
export default simulationSlice.reducer;
