/* eslint-disable max-len */
import React from 'react';
var LinkIcon = function () {
    return (React.createElement("svg", { width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_538_210036)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.636 4C8.636 3.86739 8.58332 3.74021 8.48955 3.64645C8.39579 3.55268 8.26861 3.5 8.136 3.5H1.5C1.10218 3.5 0.720644 3.65804 0.43934 3.93934C0.158035 4.22064 0 4.60218 0 5V15C0 15.3978 0.158035 15.7794 0.43934 16.0607C0.720644 16.342 1.10218 16.5 1.5 16.5H11.5C11.8978 16.5 12.2794 16.342 12.5607 16.0607C12.842 15.7794 13 15.3978 13 15V8.364C13 8.23139 12.9473 8.10421 12.8536 8.01045C12.7598 7.91668 12.6326 7.864 12.5 7.864C12.3674 7.864 12.2402 7.91668 12.1464 8.01045C12.0527 8.10421 12 8.23139 12 8.364V15C12 15.1326 11.9473 15.2598 11.8536 15.3536C11.7598 15.4473 11.6326 15.5 11.5 15.5H1.5C1.36739 15.5 1.24021 15.4473 1.14645 15.3536C1.05268 15.2598 1 15.1326 1 15V5C1 4.86739 1.05268 4.74021 1.14645 4.64645C1.24021 4.55268 1.36739 4.5 1.5 4.5H8.136C8.26861 4.5 8.39579 4.44732 8.48955 4.35355C8.58332 4.25979 8.636 4.13261 8.636 4Z" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 1C16 0.867392 15.9473 0.740215 15.8536 0.646447C15.7598 0.552678 15.6326 0.5 15.5 0.5H10.5C10.3674 0.5 10.2402 0.552678 10.1464 0.646447C10.0527 0.740215 10 0.867392 10 1C10 1.13261 10.0527 1.25979 10.1464 1.35355C10.2402 1.44732 10.3674 1.5 10.5 1.5H14.293L6.146 9.646C6.09951 9.69249 6.06264 9.74768 6.03748 9.80842C6.01232 9.86916 5.99937 9.93426 5.99937 10C5.99937 10.0657 6.01232 10.1308 6.03748 10.1916C6.06264 10.2523 6.09951 10.3075 6.146 10.354C6.19249 10.4005 6.24768 10.4374 6.30842 10.4625C6.36916 10.4877 6.43426 10.5006 6.5 10.5006C6.56574 10.5006 6.63084 10.4877 6.69158 10.4625C6.75232 10.4374 6.80751 10.4005 6.854 10.354L15 2.207V6C15 6.13261 15.0527 6.25979 15.1464 6.35355C15.2402 6.44732 15.3674 6.5 15.5 6.5C15.6326 6.5 15.7598 6.44732 15.8536 6.35355C15.9473 6.25979 16 6.13261 16 6V1Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_538_210036" },
                React.createElement("rect", { width: "16", height: "16", fill: "white", transform: "translate(0 0.5)" })))));
};
export default LinkIcon;
