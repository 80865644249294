import { useEffect, useRef, useState } from 'react';
import { selectSelectedFilters } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getJobZeroRequest, selectJobZeroDataNextPage } from 'store/jobZeroSlice';
import { getSessionId } from 'utils';
export var useLoadNextPage = function (shouldLoad, jobZeroDataLoading) {
    var dispatch = useAppDispatch();
    var sessionId = getSessionId();
    var selectedFilters = useAppSelector(selectSelectedFilters);
    var hasNextPage = Boolean(useAppSelector(selectJobZeroDataNextPage));
    var _a = useState(false), isNextPageLoading = _a[0], setIsNextPageLoading = _a[1];
    var observerTarget = useRef(null);
    var fetchJobZeroDocuments = function () {
        setIsNextPageLoading(true);
        dispatch(getJobZeroRequest({
            sessionId: sessionId,
            country: selectedFilters.selectedCountry,
            productFamily: selectedFilters.selectedProductFamily,
            resourceType: selectedFilters.selectedResourceType,
            deliveryMethod: selectedFilters.selectedDeliveryMethod,
            deploymentSupplier: selectedFilters.selectedDeploymentSupplier,
        }));
    };
    useEffect(function () {
        if (!jobZeroDataLoading) {
            setIsNextPageLoading(false);
        }
    }, [jobZeroDataLoading]);
    useEffect(function () {
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting
                && shouldLoad
                && !jobZeroDataLoading
                && hasNextPage) {
                fetchJobZeroDocuments();
            }
        }, { threshold: 1 });
        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }
        return function () {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [
        observerTarget,
        selectedFilters,
        shouldLoad,
        jobZeroDataLoading,
        hasNextPage,
    ]);
    return {
        observerTarget: observerTarget,
        isNextPageLoading: isNextPageLoading,
    };
};
