import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/scss/styles.scss';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
var container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
var root = ReactDOM.createRoot(container);
// eslint-disable-next-line jest/require-hook
root.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)));
