import React from 'react';
import ArrowLeft from 'assets/images/ArrowLeft';
import ArrowRight from 'assets/images/ArrowRight';
var BundlesNavigation = function (props) {
    var onPrev = props.onPrev, onNext = props.onNext;
    return (React.createElement("div", { className: "d-flex justify-content-start" },
        React.createElement("button", { className: "me-3 btn btn-outline-primary custom-button", onClick: onPrev, type: "button", disabled: !onPrev },
            React.createElement(ArrowLeft, null),
            React.createElement("span", { className: "ms-2" }, "Previous Module Type")),
        React.createElement("button", { className: "btn btn-outline-primary custom-button", onClick: onNext, type: "button", disabled: !onNext },
            React.createElement("span", { className: "me-2" }, "Next Module Type"),
            React.createElement(ArrowRight, null))));
};
export default BundlesNavigation;
