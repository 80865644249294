var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectModulesBundles } from 'store/modules/slice';
import { isEntireBundleSelected, setEntireBundleSelection, setGroupSelection, setModuleSelection, updateBundles, validateSelection, } from './helpers';
export var ActionType;
(function (ActionType) {
    ActionType[ActionType["UPDATED_BUNDLES"] = 0] = "UPDATED_BUNDLES";
    ActionType[ActionType["TOGGLE_ALL"] = 1] = "TOGGLE_ALL";
    ActionType[ActionType["TOGGLE_MODULE"] = 2] = "TOGGLE_MODULE";
    ActionType[ActionType["TOGGLE_GROUP"] = 3] = "TOGGLE_GROUP";
})(ActionType || (ActionType = {}));
var initialState = [];
function reducer(state, action) {
    var _a, _b, _c, _d, _e;
    switch (action.type) {
        case ActionType.UPDATED_BUNDLES: {
            return updateBundles(state, (_a = action.data) !== null && _a !== void 0 ? _a : []);
        }
        case ActionType.TOGGLE_MODULE: {
            var bundleIndex = action.bundleIndex, moduleIndex = action.moduleIndex;
            var isSelected = (_c = (_b = state[bundleIndex]) === null || _b === void 0 ? void 0 : _b.modules[moduleIndex]) === null || _c === void 0 ? void 0 : _c.selected;
            return setModuleSelection(state, bundleIndex, moduleIndex, !isSelected);
        }
        case ActionType.TOGGLE_ALL: {
            var bundleIndex = action.bundleIndex;
            var isAllSelected = isEntireBundleSelected(state[bundleIndex]);
            return setEntireBundleSelection(state, bundleIndex, !isAllSelected);
        }
        case ActionType.TOGGLE_GROUP: {
            var bundleIndex = action.bundleIndex, groupIndex = action.groupIndex;
            var isSelected = (_e = (_d = state[bundleIndex]) === null || _d === void 0 ? void 0 : _d.groups[groupIndex]) === null || _e === void 0 ? void 0 : _e.selected;
            return setGroupSelection(state, bundleIndex, groupIndex, !isSelected);
        }
        default:
            console.error('Wrong useBundles action', action); // eslint-disable-line no-console
            return state;
    }
}
export default function useBundles() {
    var bundlesFromRedux = useAppSelector(selectModulesBundles);
    var _a = useReducer(reducer, initialState), bundles = _a[0], bundlesDispatch = _a[1];
    var _b = useState(false), bundlesIsActive = _b[0], setBundlesIsActive = _b[1];
    var _c = useState(-1), activeBundleIndex = _c[0], setActiveBundleIndex = _c[1];
    var _d = useState([]), bundlesIndexesList = _d[0], setBundlesIndexesList = _d[1];
    useEffect(function () {
        bundlesDispatch({ type: ActionType.UPDATED_BUNDLES, data: bundlesFromRedux });
        setBundlesIsActive(Boolean(bundlesFromRedux));
        var indexes = (bundlesFromRedux !== null && bundlesFromRedux !== void 0 ? bundlesFromRedux : []).flatMap(function (bundle) { return bundle.modules.length > 1 ? [bundle.index] : []; });
        setBundlesIndexesList(indexes);
        if (!indexes.length) {
            setActiveBundleIndex((bundlesFromRedux === null || bundlesFromRedux === void 0 ? void 0 : bundlesFromRedux.length) ? 0 : -1);
            return;
        }
        if (activeBundleIndex < 0) {
            setActiveBundleIndex(indexes[0]);
        }
    }, [bundlesFromRedux]);
    var bundlesIndexesCallbacks = useMemo(function () {
        var idx = bundlesIndexesList.findIndex(function (i) { return i === activeBundleIndex; });
        var nextIndex = idx > -1 && (idx + 1) < bundlesIndexesList.length ? bundlesIndexesList[idx + 1] : -1;
        var prevIndex = idx > 0 ? bundlesIndexesList[idx - 1] : -1;
        var createHandler = function (index) { return index > -1
            ? function () { return setActiveBundleIndex(index); }
            : undefined; };
        return {
            setNextBundleActive: createHandler(nextIndex),
            setPrevBundleActive: createHandler(prevIndex),
        };
    }, [bundlesIndexesList, activeBundleIndex]);
    var selection = useMemo(function () { return ({
        selectedCount: bundles.reduce(function (selected, bundle) { return selected + bundle.selectedCount; }, 0),
        selectionIsValid: validateSelection(bundles),
    }); }, [bundles]);
    return __assign(__assign(__assign({ bundles: bundles, bundlesIsActive: bundlesIsActive, bundlesDispatch: bundlesDispatch }, selection), { activeBundleIndex: activeBundleIndex, setActiveBundleIndex: setActiveBundleIndex }), bundlesIndexesCallbacks);
}
