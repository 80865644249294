import React, { useEffect, useRef, useState } from 'react';
import { getRendition } from 'api';
import LoadingSpinner from 'components/LoadingSpinner';
import { getSessionId } from 'utils';
var LayoutPreview = React.forwardRef(function (props, ref) {
    var documentId = props.documentId, _a = props.alt, alt = _a === void 0 ? '' : _a, _b = props.stringifiedClassName, stringifiedClassName = _b === void 0 ? '' : _b;
    var _c = useState(''), source = _c[0], setSource = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var loadingPromise = useRef();
    var sessionId = getSessionId();
    useEffect(function () {
        setLoading(true);
        var promise = getRendition(sessionId, documentId)
            .then(function (imageSource) {
            if (promise !== loadingPromise.current) {
                return;
            }
            setSource(imageSource);
            setLoading(false);
        })
            .catch(function (error) {
            setSource('');
            setLoading(false);
            console.error(error); // eslint-disable-line no-console
        });
        loadingPromise.current = promise;
    }, [documentId]);
    if (loading) {
        return (React.createElement("div", { ref: ref, className: "d-flex flex-column align-items-center my-5" },
            React.createElement(LoadingSpinner, null)));
    }
    else if (!source) {
        return null;
    }
    return (React.createElement("div", { className: "w-100 lh-zero ".concat(stringifiedClassName), ref: ref },
        React.createElement("img", { className: "w-100", src: source, alt: alt })));
});
LayoutPreview.displayName = 'LayoutPreview';
export default LayoutPreview;
