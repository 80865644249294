/* eslint-disable max-len */
import React from 'react';
var NoModules = function () {
    return (React.createElement("svg", { width: "134", height: "90", viewBox: "0 0 134 90", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_573_205849)" },
            React.createElement("rect", { width: "134", height: "90", fill: "white", fillOpacity: "0.01" }),
            React.createElement("path", { d: "M68.1355 89.332C108.274 89.332 140.813 82.3328 140.813 73.6989C140.813 65.065 108.274 58.0658 68.1355 58.0658C27.9966 58.0658 -4.54248 65.065 -4.54248 73.6989C-4.54248 82.3328 27.9966 89.332 68.1355 89.332Z", fill: "#F5F5F5" }),
            React.createElement("path", { d: "M120.373 30.7469L97.3293 5.05949C96.2232 3.30858 94.6084 2.25 92.9073 2.25H43.3637C41.6625 2.25 40.0477 3.30858 38.9417 5.05726L15.8982 30.7491V51.3826H120.373V30.7469Z", stroke: "#CED4DA" }),
            React.createElement("path", { d: "M89.9684 37.8286C89.9684 34.2442 92.226 31.2851 95.0263 31.2828H120.373V71.7882C120.373 76.5294 117.375 80.4154 113.673 80.4154H22.5982C18.8962 80.4154 15.8982 76.5272 15.8982 71.7882V31.2828H41.2446C44.045 31.2828 46.3026 34.2375 46.3026 37.8219V37.8711C46.3026 41.4555 48.5851 44.3499 51.3832 44.3499H84.8878C87.6859 44.3499 89.9684 41.4287 89.9684 37.8443V37.8286Z", fill: "#FAFAFA", stroke: "#CED4DA" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_573_205849" },
                React.createElement("rect", { width: "134", height: "90", fill: "white" })))));
};
export default NoModules;
