var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
var initialState = {
    success: false,
    status: SAGA_STATUS.IDLE,
    error: '',
};
export var recipeSlice = createSlice({
    name: 'recipe',
    initialState: initialState,
    reducers: {
        getRecipeStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        getRecipeSuccess: function (state, action) {
            state.success = action.payload;
            state.status = SAGA_STATUS.IDLE;
        },
        getRecipeError: function (state, action) {
            var _a;
            state.status = SAGA_STATUS.IDLE;
            state.error = (_a = action.payload) !== null && _a !== void 0 ? _a : 'Unknown recipe error';
        },
        resetRecipe: function () { return initialState; },
    },
});
export var getRecipeRequest = createAction('getRecipeRequest');
export var selectRecipeDataLoadingStatus = function (state) {
    return state.recipe.status === SAGA_STATUS.PENDING;
};
export var selectRecipeData = function (state) {
    return state.recipe.success;
};
export var selectRecipeError = function (state) {
    return state.recipe.error;
};
export var getRecipeStart = (_a = recipeSlice.actions, _a.getRecipeStart), getRecipeSuccess = _a.getRecipeSuccess, getRecipeError = _a.getRecipeError, resetRecipe = _a.resetRecipe;
export default recipeSlice.reducer;
