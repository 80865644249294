import _ from 'lodash';
import { DEFAULT_SECTION_WIDTH, ProjectsConfig } from 'const';
// unused since DCC-7735 - hardcoded 800px
export var getSectionWidth = function (previewOptions, projectType) {
    var defaultPreviewOption = ProjectsConfig[projectType].defaultPreviewOption;
    var previewOption = _.find(previewOptions, { name: defaultPreviewOption });
    if (_.isEmpty(previewOption)) {
        previewOption = previewOptions[0];
    }
    return parseFloat(previewOption.width) || DEFAULT_SECTION_WIDTH;
};
