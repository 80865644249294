import React, { Fragment, memo } from 'react';
import BiLayersFill from 'assets/images/bi-layers-fill.svg';
import styles from '../Combinations.module.scss';
import { isCombinationItemWithGroup } from '../useCombinations';
var CombinationsListItem = function (props) {
    var sectionsToView = props.sectionsToView, combination = props.combination, index = props.index, active = props.active, selected = props.selected, onClick = props.onClick, onToggle = props.onToggle;
    var activeClass = active ? styles.container__left__combinations__combination_active : '';
    var handler = function () { return onClick(index); };
    return (React.createElement("div", { onClick: handler, onKeyUp: handler, className: "".concat(activeClass, " ").concat(styles.container__left__combinations__combination) },
        React.createElement("div", { className: styles.container__left__combinations__combination__header },
            React.createElement("input", { className: "form-check-input", type: "checkbox", onChange: function () { return onToggle(index); }, checked: selected }),
            "#",
            index + 1),
        React.createElement("div", { className: styles.container__left__combinations__combination__items }, sectionsToView.map(function (_a) {
            var section = _a[0], idx = _a[1];
            var item = combination[idx];
            var number = 'none';
            if (item) {
                number = isCombinationItemWithGroup(item)
                    ? React.createElement(React.Fragment, null,
                        React.createElement(BiLayersFill, null),
                        " ",
                        item.modules.map(function (i) { return i + 1; }).join(', '))
                    : (item.moduleIdx + 1).toString();
            }
            return (React.createElement(Fragment, { key: section.section.id },
                React.createElement("div", { className: "zone" }, section.section.displayname),
                React.createElement("div", { className: "number" }, number)));
        }))));
};
export default memo(CombinationsListItem);
