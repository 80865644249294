import React, { useEffect, useState } from 'react';
import IframePlaceholder from 'pages/Channels/IframePlaceholder';
import Home from 'pages/Home/Home';
import Loading from 'pages/Home/Loading';
import { selectIsAuthenticated, signInFailure } from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserDataRequest, selectIsUserDataFetchInProgress, selectUserDataError } from 'store/userSlice';
import { getIframeSessionId, getSessionId, removeSessionId } from 'utils/sessionStorage';
var isInIframe = window.self !== window.top;
if (isInIframe) {
    getIframeSessionId();
}
var AuthProvider = function (_a) {
    var children = _a.children;
    var dispatch = useAppDispatch();
    var userError = useAppSelector(selectUserDataError);
    var userIsLoading = useAppSelector(selectIsUserDataFetchInProgress);
    var isAuthenticated = useAppSelector(selectIsAuthenticated);
    // sessionIdInState is used to trigger rerender after sessionId change
    var sessionId = getSessionId();
    var _b = useState(sessionId), sessionIdInState = _b[0], setSessionIdInState = _b[1];
    useEffect(function () {
        if (sessionId === sessionIdInState) {
            return;
        }
        setSessionIdInState(sessionId);
    }, [sessionId, sessionIdInState]);
    useEffect(function () {
        if (!sessionId) {
            return;
        }
        if (userError) {
            removeSessionId();
            dispatch(signInFailure());
            setSessionIdInState('');
        }
        else {
            dispatch(getUserDataRequest());
        }
    }, [sessionId, userError, isAuthenticated]);
    if (isAuthenticated) {
        return React.createElement(React.Fragment, null, children);
    }
    if (isInIframe) {
        return React.createElement(IframePlaceholder, null);
    }
    if (userIsLoading) {
        return React.createElement(Loading, null);
    }
    return React.createElement(Home, null);
};
export default AuthProvider;
