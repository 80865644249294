import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/Logo';
import styles from './NavBar.module.scss';
var NavBar = function (_a) {
    var onBrandClick = _a.onBrandClick, textContent = _a.textContent, children = _a.children, _b = _a.isModuleCombinationLabelShown, isModuleCombinationLabelShown = _b === void 0 ? false : _b, _c = _a.discardPositionFixed, discardPositionFixed = _c === void 0 ? false : _c;
    var classes = classNames('navbar navbar-expand-md py-2', styles.navBar, { discardPositionFixed: discardPositionFixed });
    // user on simulation page should not be able to click on logo and go to filters
    var clickAction = window.location.pathname.includes('simulation')
        ? function (e) { e.preventDefault(); }
        : onBrandClick;
    return (React.createElement("nav", { className: classes },
        React.createElement("div", { className: "container-fluid justify-content-start mx-sm-1" },
            React.createElement(Link, { className: "navbar-brand d-flex align-items-center me-3", to: "/", onClick: clickAction },
                React.createElement(Logo, null),
                React.createElement("div", null,
                    React.createElement("h1", { className: "".concat(styles.heading, " ms-2 py-0 mb-0") }, "Generative Marketing Personalization"),
                    isModuleCombinationLabelShown && (React.createElement("p", { className: styles.moduleCombinationLabel }, "Module Combination Simulation")))),
            textContent
                ? React.createElement("span", { className: styles.textContent }, textContent)
                : null,
            children)));
};
export default NavBar;
