var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function replaceBundle(bundles, bundleIndex, newBundle) {
    var newState = bundles.map(function (i) { return i; });
    newState[bundleIndex] = __assign(__assign({}, newBundle), { index: bundleIndex });
    return newState;
}
export function replaceGroupsInBundle(bundle, newGroups) {
    var createModulesSignature = function (group) {
        return group.modules.map(function (module) { return module.index; }).toString();
    };
    var groupsWithCheckedDuplicates = newGroups.map(function (group) {
        var signature = createModulesSignature(group);
        var hasDuplicates = newGroups.some(function (item) { return item !== group && signature === createModulesSignature(item); });
        return __assign(__assign({}, group), { hasDuplicates: hasDuplicates });
    });
    return __assign(__assign({}, bundle), { groups: groupsWithCheckedDuplicates });
}
export function replaceBundleGroups(bundles, bundleIndex, groups) {
    var bundle = bundles[bundleIndex];
    if (!bundle) {
        return bundles;
    }
    var updatedBundle = replaceGroupsInBundle(bundle, groups);
    return replaceBundle(bundles, bundleIndex, updatedBundle);
}
export function addBundleGroup(bundles, bundleIndex, moduleIndexes) {
    var _a, _b;
    if (!bundles[bundleIndex]) {
        return bundles;
    }
    var modules = ((_b = (_a = bundles[bundleIndex]) === null || _a === void 0 ? void 0 : _a.modules) !== null && _b !== void 0 ? _b : [])
        .filter(function (module) { return moduleIndexes.includes(module.index); })
        .map(function (module) { return (__assign({}, module)); });
    var groups = bundles[bundleIndex].groups;
    var newGroup = {
        index: groups.length,
        hasDuplicates: false,
        selected: false,
        modules: modules,
    };
    var updatedGroups = groups.map(function (i) { return i; }); // for sparse array
    updatedGroups[newGroup.index] = newGroup;
    return replaceBundleGroups(bundles, bundleIndex, updatedGroups);
}
export function getPersistentBundlesGroups(bundles) {
    return bundles.flatMap(function (bundle) {
        var groups = bundle.groups.filter(function (i) { return i; });
        if (!groups.length) {
            return [];
        }
        return {
            bundleIndex: bundle.index,
            groups: groups.map(function (group) { return group.modules.map(function (module) { return module.index; }); }),
        };
    });
}
