var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useRef } from 'react';
import styles from '../Combinations.module.scss';
var CombinationsListToggle = function (props) {
    var combinations = props.combinations, selectedIndexes = props.selectedIndexes, onChangeSelection = props.onChangeSelection;
    var combinationsIndexes = useMemo(function () { return combinations.flatMap(function (item, idx) { return item ? [idx] : []; }); }, [combinations]);
    var totalNumber = combinationsIndexes.length;
    var selectedNumber = selectedIndexes.length;
    var inputRef = useRef(null);
    useEffect(function () {
        if (!inputRef.current) {
            return;
        }
        inputRef.current.indeterminate = totalNumber > 0 && selectedNumber > 0 && selectedNumber < totalNumber;
    }, [totalNumber, selectedNumber]);
    var onClick = function () { return onChangeSelection(function (list) {
        if (combinationsIndexes.every(function (idx) { return list.includes(idx); })) {
            return [];
        }
        else {
            return __spreadArray([], combinationsIndexes, true);
        }
    }); };
    return (React.createElement("div", { className: styles.container__left__toggleall },
        React.createElement("input", { ref: inputRef, className: "form-check-input", type: "checkbox", onChange: onClick, checked: totalNumber === selectedNumber }),
        React.createElement("span", null, "".concat(selectedNumber, "/").concat(totalNumber, " combinations"))));
};
export default CombinationsListToggle;
