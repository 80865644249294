import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Channels from 'pages/Channels';
import IframePlaceholder from 'pages/Channels/IframePlaceholder';
import Combinations from 'pages/Combinations/Combinations';
import Filters from 'pages/Filters/Filters';
import Home from 'pages/Home/Home';
import Modules from 'pages/Modules/Modules';
import 'react-toastify/dist/ReactToastify.css';
import Simulation from 'pages/Simulation';
import store from 'store';
import { AppRoute } from 'types';
import AuthProvider from './pages/AuthProvider';
var App = function () {
    return (React.createElement(Provider, { store: store },
        React.createElement(ToastContainer, null),
        React.createElement(BrowserRouter, null,
            React.createElement(AuthProvider, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: AppRoute.HOME, element: React.createElement(Home, null) }),
                    React.createElement(Route, { path: AppRoute.FILTERS, element: React.createElement(Filters, null) }),
                    React.createElement(Route, { path: AppRoute.MODULES, element: React.createElement(Modules, null) }),
                    React.createElement(Route, { path: AppRoute.COMBINATIONS, element: React.createElement(Combinations, null) }),
                    React.createElement(Route, { path: AppRoute.CHANNELS, element: React.createElement(Channels, null) }),
                    React.createElement(Route, { path: AppRoute.SIMULATION, element: React.createElement(Simulation, null) }),
                    React.createElement(Route, { path: AppRoute.IFRAME_PLACEHOLDER, element: React.createElement(IframePlaceholder, null) }))))));
};
export default App;
