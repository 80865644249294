var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
var initialState = {
    user: {
        firstName: '',
        lastName: '',
        userName: '',
        userEmail: '',
    },
    usersContacts: [],
    status: SAGA_STATUS.IDLE,
    error: '',
};
export var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        getUserDataStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        getUserDataSuccess: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.user = {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                userName: action.payload.userName,
                userEmail: action.payload.userEmail,
            };
            state.usersContacts = action.payload.usersContacts;
        },
        getUserDataError: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.error = action.payload;
        },
    },
});
export var getUserDataRequest = createAction('getUserDataRequest');
export var selectUserDataError = function (state) {
    return state.user.error;
};
export var selectIsUserDataFetchInProgress = function (state) {
    return state.user.status === SAGA_STATUS.PENDING;
};
export var selectFullUserName = function (state) {
    var firstName = state.user.user.firstName;
    var lastName = state.user.user.lastName;
    if (firstName || lastName) {
        return "".concat(firstName, " ").concat(lastName);
    }
    return '';
};
export var selectOwnerEmail = function (ownerName) {
    return function (state) {
        return state.user.usersContacts.find(function (userContact) { return userContact.name === ownerName; }).email;
    };
};
export var selectCurrentUserName = function (state) {
    return state.user.user.userName;
};
export var selectCurrentUserEmail = function (state) {
    return state.user.user.userEmail;
};
export var selectUserNames = function (state) {
    return state.user.usersContacts.map(function (userContact) { return userContact.name; });
};
export var getUserDataStart = (_a = userSlice.actions, _a.getUserDataStart), getUserDataSuccess = _a.getUserDataSuccess, getUserDataError = _a.getUserDataError;
export default userSlice.reducer;
