var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
export var initialState = {
    jobZeroDocuments: [],
    nextPage: '',
    lastSelectedDocumentId: '',
    status: SAGA_STATUS.IDLE,
    error: '',
};
export var jobZeroSlice = createSlice({
    name: 'jobZero',
    initialState: initialState,
    reducers: {
        getJobZeroStart: function (state) {
            state.status = SAGA_STATUS.PENDING;
            state.error = '';
        },
        getJobZeroSuccess: function (state, action) {
            var _a = action.payload, data = _a.data, nextPage = _a.nextPage;
            state.jobZeroDocuments = __spreadArray(__spreadArray([], state.jobZeroDocuments, true), data, true);
            state.nextPage = nextPage;
            state.status = SAGA_STATUS.IDLE;
        },
        getJobZeroError: function (state, action) {
            state.status = SAGA_STATUS.IDLE;
            state.error = action.payload;
        },
        setLastSelectedDocumentId: function (state, action) {
            state.lastSelectedDocumentId = action.payload;
        },
        setNextPage: function (state, action) {
            state.nextPage = action.payload;
        },
        reset: function () { return initialState; },
    },
});
export var getJobZeroRequest = createAction('getJobZeroRequest');
export var selectJobZeroData = function (state) {
    return state.jobZero.jobZeroDocuments;
};
export var selectJobZeroDataLoadingStatus = function (state) {
    return state.jobZero.status === SAGA_STATUS.PENDING;
};
export var selectJobZeroDataNextPage = function (state) {
    return state.jobZero.nextPage;
};
export var selectLastSelectedDocumentId = function (state) {
    return state.jobZero.lastSelectedDocumentId;
};
export var getJobZeroStart = (_a = jobZeroSlice.actions, _a.getJobZeroStart), getJobZeroSuccess = _a.getJobZeroSuccess, getJobZeroError = _a.getJobZeroError, reset = _a.reset, setLastSelectedDocumentId = _a.setLastSelectedDocumentId, setNextPage = _a.setNextPage;
export default jobZeroSlice.reducer;
