var STORAGE_KEY = 'persistentState';
export function clearStorageState() {
    sessionStorage.removeItem(STORAGE_KEY);
}
export function saveToLocalStorage(state) {
    var serialisedState = JSON.stringify(state);
    sessionStorage.setItem(STORAGE_KEY, serialisedState);
}
export function loadFromLocalStorage() {
    var serialisedState = sessionStorage.getItem(STORAGE_KEY);
    if (serialisedState === null) {
        return undefined;
    }
    return JSON.parse(serialisedState);
}
